.loader {
  width: 90px;
  height: 90px;
  border: 5px solid;
  border-color: #FF3D00 transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  backdrop-filter: blur(100%);
}



@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fixHeight .css-13cymwt-control {
  max-height: 150px;
  overflow-y: scroll;
}

.fixHeight svg {
  vertical-align: top;
}

.fixHeight .css-1hb7zxy-IndicatorsContainer {
  align-items: flex-start;
}


@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fixHeight .css-13cymwt-control {
  max-height: 150px;
  overflow-y: scroll;
}

.fixHeight svg {
  vertical-align: top;
}

.fixHeight .css-1hb7zxy-IndicatorsContainer {
  align-items: flex-start;
}

.mixed-chart .apexcharts-legend {
  right: 140px !important;
}

/* sld style */
.treeHead {
  font-size: 17px;
}

.rd3t-label__attributes {
  font-size: 13px !important;
}

.rd3t-leaf-node{
  fill: #777 !important;
  stroke-width: 2 !important;
}

.checkbox-vertical {
  background-color: gray;
}

.checkbox-horizontal {
  background-color: blue;
}

.bold-link {
  stroke-width: 4px; 
  stroke: black; 
  
}

.hover-table{
  width:200px;
  /* position: absolute; */
  background: #f5f5fa;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
   border: 2px solid #000;
    border-radius: 5px;
}
