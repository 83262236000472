@media print {
  .page-main {
    padding-top: 50px;
    padding-bottom: 50px;
    display: block;
  }

  .watermark {
    position: fixed;
    top: 50vh;
    z-index: 9;
    width: 50vw;
    page-break-after: always;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.2;
  }
}

.info-box {
  min-height: 40px !important;
}

.info-box .info-box-icon {
  width: 30px !important;
}

.bg-greenPin {
  background-color: #92D050 !important;
}

.bg-redPin {
  background-color: #FF0000 !important;
}

.bg-grayPin {
  background-color: #657790 !important;
}

.info-box .info-box-text {
  font-size: 16px !important;
  font-weight: 700 !important;
}
@-moz-document url-prefix() { 
  .info-box .info-box-text{font-weight: 500!important;}
}

/* background color for status */

.good {
  background: #00B050;
  padding: 3px; border-radius: 5px; color: #fff; font-weight: 500;
}

.Good {
  display: inline-block;
  background: #00B050;
}

.moderate {
  background: #D4CA2F;
  padding: 3px; border-radius: 5px; color: #fff; font-weight: 500;
}

.Moderate {
  display: inline-block;
  background: #D4CA2F;
}

.poor {
  background: #FF9900;
  padding: 3px; border-radius: 5px; color: #fff; font-weight: 500;
}

.Poor {
  display: inline-block;
  background: #FF9900;
}

.satisfactory {
  background: #92D050; width: 90px; word-break: auto-phrase;
  padding: 3px; border-radius: 5px; color: #fff; font-weight: 500;
}

.Satisfactory {
  display: inline-block;
  background: #92D050;
}

.severe {
  background: #BF0000;
  padding: 3px; border-radius: 5px; color: #fff; font-weight: 500;
}

.Severe {
  display: inline-block;
  background: #BF0000;
}

.very_Poor {
  display: inline-block;
  width: 140px;
  background: #FF0000;
  padding: 3px; border-radius: 5px; color: #fff; font-weight: 500;
}

.Very_Poor {
  display: inline-block;
  width: 90px;
  background: #FF0000;
}