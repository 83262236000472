/* #### Generated By: http://font.download #### */

    /* @font-face {
    font-family: 'Montserrat Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Montserrat Regular'), url('Montserrat-Regular.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Montserrat Italic'), url('Montserrat-Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Montserrat Thin'), url('Montserrat-Thin.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat Thin Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Montserrat Thin Italic'), url('Montserrat-ThinItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat ExtraLight';
    font-style: normal;
    font-weight: normal;
    src: local('Montserrat ExtraLight'), url('Montserrat-ExtraLight.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat ExtraLight Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Montserrat ExtraLight Italic'), url('Montserrat-ExtraLightItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat Light';
    font-style: normal;
    font-weight: normal;
    src: local('Montserrat Light'), url('Montserrat-Light.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Montserrat Light Italic'), url('Montserrat-LightItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Montserrat Medium'), url('Montserrat-Medium.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat Medium Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Montserrat Medium Italic'), url('Montserrat-MediumItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat SemiBold';
    font-style: normal;
    font-weight: normal;
    src: local('Montserrat SemiBold'), url('Montserrat-SemiBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat SemiBold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Montserrat SemiBold Italic'), url('Montserrat-SemiBoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Montserrat Bold'), url('Montserrat-Bold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Montserrat Bold Italic'), url('Montserrat-BoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat ExtraBold';
    font-style: normal;
    font-weight: normal;
    src: local('Montserrat ExtraBold'), url('Montserrat-ExtraBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat ExtraBold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Montserrat ExtraBold Italic'), url('Montserrat-ExtraBoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat Black';
    font-style: normal;
    font-weight: normal;
    src: local('Montserrat Black'), url('Montserrat-Black.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat Black Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Montserrat Black Italic'), url('Montserrat-BlackItalic.woff') format('woff');
    }

   */
/* @font-face {
    font-family: 'Montserrat';
    src: 
         url('/src/assets/fonts/Montserrat-Regular.woff') format('woff'),
         url('/src/assets/fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: 550;
    font-style: normal;
  } 
  @font-face {
    font-family: 'Montserrat';
    src: 
         url('/src/assets/fonts/Montserrat-Medium.woff') format('woff'),
         url('/src/assets/fonts/Montserrat-Medium.ttf') format('truetype');
    font-weight: 550;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Montserrat';
    src: 
    
         url('/src/assets/fonts/Montserrat-Bold.woff') format('woff'),
         url('/src/assets/fonts/Montserrat-Bold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }
   */

   /* cyrillic-ext */
@font-face {
     font-family: 'Montserrat';
     font-style: italic;
     font-weight: 100 900;
     font-display: swap;
     src: url('/src/assets/fonts/font1.woff2') format('woff2');
     unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
   }
   /* cyrillic */
   @font-face {
     font-family: 'Montserrat';
     font-style: italic;
     font-weight: 100 900;
     font-display: swap;
     src: url('/src/assets/fonts/font2.woff2') format('woff2');
     unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
   }
   /* vietnamese */
   @font-face {
     font-family: 'Montserrat';
     font-style: italic;
     font-weight: 100 900;
     font-display: swap;
     src: url('/src/assets/fonts/font3.woff2') format('woff2');
     unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
   }
   /* latin-ext */
   @font-face {
     font-family: 'Montserrat';
     font-style: italic;
     font-weight: 100 900;
     font-display: swap;
     src: url('/src/assets/fonts/font4.woff2') format('woff2');
     unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
   }
   /* latin */
   @font-face {
     font-family: 'Montserrat';
     font-style: italic;
     font-weight: 100 900;
     font-display: swap;
     src: url('/src/assets/fonts/font5.woff2') format('woff2');
     unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
   }
   /* cyrillic-ext */
   @font-face {
     font-family: 'Montserrat';
     font-style: normal;
     font-weight: 100 900;
     font-display: swap;
     src: url('/src/assets/fonts/font6.woff2') format('woff2');
     unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
   }
   /* cyrillic */
   @font-face {
     font-family: 'Montserrat';
     font-style: normal;
     font-weight: 100 900;
     font-display: swap;
     src: url('/src/assets/fonts/font7.woff2') format('woff2');
     unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
   }
   /* vietnamese */
   @font-face {
     font-family: 'Montserrat';
     font-style: normal;
     font-weight: 100 900;
     font-display: swap;
     src: url('/src/assets/fonts/font8.woff2') format('woff2');
     unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
   }
   /* latin-ext */
   @font-face {
     font-family: 'Montserrat';
     font-style: normal;
     font-weight: 100 900;
     font-display: swap;
     src: url('/src/assets/fonts/font9.woff2') format('woff2');
     unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
   }
   /* latin */
   @font-face {
     font-family: 'Montserrat';
     font-style: normal;
     font-weight: 100 900;
     font-display: swap;
     src: url('/src/assets/fonts/font10.woff2') format('woff2');
     unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
   }