.loader {
  width: 90px;
  height: 90px;
  border: 5px solid;
  border-color: #FF3D00 transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  backdrop-filter: blur(100%);
}



@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fixHeight .css-13cymwt-control {
  max-height: 150px;
  overflow-y: scroll;
}

.fixHeight svg {
  vertical-align: top;
}

.fixHeight .css-1hb7zxy-IndicatorsContainer {
  align-items: flex-start;
}

  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 

  .fixHeight .css-13cymwt-control{max-height: 150px; overflow-y: scroll;}
  .fixHeight svg{vertical-align: top;}
  .fixHeight .css-1hb7zxy-IndicatorsContainer{align-items: flex-start;}
  .mixed-chart .apexcharts-legend{right: 140px!important;}
  .hotspotChart .apexcharts-canvas{padding-top: 25px;}
  .hotspotChart .inrCardBody{padding: 10px 20px 30px 20px;}
