/* left tree */

.new2tree,
.newtree {
    display: inline-block;
    vertical-align: top;
    /* To align them at the top if needed */
    /* Add any other styling you want for these divs */
}

.new2tree {
    margin: 2.8% 0 0 0;
}

.new2tree ul {
    position: relative;
    padding: 1em 0;
    white-space: nowrap;
    margin: 0 auto;
    text-align: center;
}

.new2tree ul::after {
    content: '';
    display: table;
    clear: both;
}

.new2tree li {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    list-style-type: none;
    position: relative;
    padding: 1em 0.5em 0 0.5em;
}

.new2tree li::before,
.new2tree li::after {
    content: '';
    position: absolute;
    top: 0;
    right: 50%;
    border-top: 1px solid #333;
    width: 50%;
    height: 1em;
}

.new2tree li::after {
    right: auto;
    left: 50%;
    border-left: 1px solid #333;
}

.new2tree li:only-child::after,
.new2tree li:only-child::before {
    display: none;
}

.new2tree li:only-child {
    padding-top: 0;
}

.new2tree li:first-child::before,
.new2tree li:last-child::after {
    border: 0 none;
}

.new2tree li:last-child::before {
    border-right: 1px solid #333;
    border-radius: 0 5px 0 0;
}

.new2tree li:first-child::after {
    border-radius: 5px 0 0 0;
}

/* .tree ul ul::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    border-left: 1px solid #333;
    width: 0;
    height: 1em;
  } */
.new2tree .new2stext {
    font-size: small;
}


.new2toptext {font-weight:bold;min-width: 120px;
    position: relative;
    font-size: small;
    top: 8.2em;
    left: 32.5em;
}

.new2toptext2 {font-weight:bold;min-width: 125px;
    position: relative;
    font-size: small;
    top: 8.2em;
    left: 32em;
}

.new2righttext {
    position: relative;
    font-size: small;
    top: 13.5em;
    left: 68.7em;font-weight:bold;min-width: 76px;
    display: inline-block;
}

.new2righttext2 {
    position: relative;
    font-size: small;
    top: 17.5em;
    left: 50.5em;font-weight:bold;min-width: 76px;
    display: inline-block;
}

.new2righttext3 {
    position: relative;
    font-size: 12px;
    top: 40.3em;
    left: 36.3em;
}

.new2righttext4 {
    position: relative;
    font-size: small;
    top: 17.2em;
    left: 7.3em;font-weight:bold;min-width: 76px;
    display: inline-block;
}

.new2mainTitle line::before {
    content: '';
    position: absolute;
    top: 0;
    left: 70% !important;
}

.new2tree li .new2mainTitle a {
    width: 300px;
}

.new2tree li a {

    padding: 0.5em 0.75em;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    position: relative;
    top: 111px;
}

.new2mainTitle {
    left: -50em;
}

.new2mainTitle a {
    position: absolute;
    color: white !important;
    background-color: #657790;
    /* padding: 3px 30px !important; */
    right: -30em;
}

.new2leftTitle {
    background-color: #0095DA;
    border-radius: 10px;
    position: relative;
    right: -30em;
}

.new2leftTitle::before {
    content: '';
    position: absolute;
    top: -78%;
    left: 47%;
    border-left: 1px solid #333;
    width: 0;
    height: 2em;
}

/* up */
.new2arrow::after {
    content: '';
    position: absolute;
    top: -222.9%;
    left: 168%;
    border-width: 6px;
    border-style: solid;
    border-color: transparent transparent black transparent;
}

/* down */
.new2arrow2 {
    content: '';
    position: absolute;
    top: 42.3%;
    left: 125.8%;
    border-width: 6px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

/* left */
.new2arrow3 {
    content: '';
    position: absolute;
    top: 45.3%;
    left: 62.9em;
    border-width: 6px;
    border-style: solid;
    border-color: transparent black transparent transparent;
    transform: rotate(360deg);
}


.new2arrow4 {
    content: '';
    position: absolute;
    top: 42%;
    left: 176.2%;
    border-width: 6px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.new2arrow5 {
    content: '';
    position: absolute;
    top: 57.3%;
    left: 51.8em;
    border-width: 6px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.new2arrow6 {
    content: '';
    position: absolute;
    top: 72.3%;
    left: 51.8em;
    border-width: 6px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.new2arrow7 {
    content: '';
    position: absolute;
    top: 89.6%;
    left: 51.8em;
    border-width: 6px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

/* right */
.new2arrow8 {
    content: '';
    position: absolute;
    top: 44.9%;
    left: 41.6em;
    border-width: 6px;
    border-style: solid;
    border-color: transparent black transparent transparent;
    transform: rotate(180deg);
}

.new2arrow9 {
    content: '';
    position: absolute;
    top: 24.4em;
    left: 41.7em;
    border-width: 6px;
    border-style: solid;
    border-color: transparent black transparent transparent;
    transform: rotate(180deg);
}

.new2arrow10 {
    content: '';
    position: absolute;
    top: 12.6%;
    left: -64%;
    border-width: 6px;
    border-style: solid;
    border-color: transparent black transparent transparent;
    transform: rotate(180deg);
}

/* three phase */
.new2threearrow10 {
    content: '';
    position: absolute;
    top: 0.4%;
    left: -75.3%;
    border-width: 6px;
    border-style: solid;
    border-color: transparent black transparent transparent;
    transform: rotate(180deg);
}

.new2threearrow11 {
    content: '';
    position: absolute;
    top: 0.4%;
    left: -44%;
    border-width: 6px;
    border-style: solid;
    border-color: transparent black transparent transparent;
    transform: rotate(0deg);
}

.new2arrow11 {
    content: '';
    position: absolute;
    top: 13.3%;
    left: -15.1%;
    border-width: 6px;
    border-style: solid;
    border-color: transparent black transparent transparent;
    transform: rotate(0deg);
}

.new2arrow12 {
    content: '';
    position: absolute;
    top: 27.7em;
    left: -35.4em;
    border-width: 6px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.new2arrow13 {
    content: '';
    position: absolute;
    top: 35.1em;
    left: -35.4em;
    border-width: 6px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.new2rightTitle {
    background-color: #0095DA;
    
    border-radius: 10px;
    right: -30em;
}

.new2rightTitle::before {
    content: '';
    position: absolute;
    top: -81%;
    left: 48%;
    border-left: 1px solid #333;
    width: 0;
    height: 2.1em;
}

.new2tree .new2rightLine::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    border-bottom: 1px solid #333;
    width: 2em;
    height: 0;
}

.new2tree .new2downLine:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 48%;
    border-left: 1px solid #333;
    width: 0;
    height: 1em;
}

.new2rightbox2 {
    background-color: #0095DA;
    
    border-radius: 10px !important;
    left: 70%;
}

.new2r2space {
    margin-top: -20% !important;
}

.new2tree .new2rightbox2::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    border-bottom: 1px solid #333;
    width: 1em;
    height: 0;
}


.new2rightbox3 {
    background-color: #0095DA;
    padding: 0.5em 1em;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    position: absolute;
    left: 172%;
    top: 18%;
}


.new2rightbox3::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    height: 1em;
    width: 0;
    /* transform: translateX(-50%); */
}

.new2rightLine {
    position: absolute;
    color: white !important;
    background-color: #657790;
    padding: 0.5em 1em;
    margin-left: 12.5em;
    right: -28.5em;
    width: 300px;
}

.new2rightbox {
    color: white !important;
    background-color: #657790;
    padding: 0.5em 2em;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    position: absolute;
    left: 172%;
    top: 29.7%;
}


.new2leftLine {
    background-color: #0095DA;
    padding: 0.5em 1em;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    position: absolute;
    left: 29.5em;
    top: 29%;
}

.new2leftLine::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    border-bottom: 1px solid #333;
    width: 2em;
    height: 0;
}

.new2leftLine2 {
    background-color: #0095DA;

    padding: 0.5em 2em;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    position: absolute;
    left: -53%;
    top: 0%;
}

.new2leftLine2::before {
    content: '';
    position: absolute;
    top: 120%;
    left: 50%;
    border-bottom: 1px solid #333;
    width: 9em;
    height: 0;
}

.new2leftLine2::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    height: 3.2em;
    width: 0;
    /* transform: translateX(-100%); */
}

.new2leftup1 {width: 200px;
    color: white !important;
    background-color: #657790;
    padding: 0.5em 1em;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    position: absolute;
    left: -80%;
    top: -31%;
}

.new2leftup1::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    border-bottom: 1px solid #333;
    width: 3.6em;
    height: 0;
}

.new2leftup1::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    height: .4em;
    width: 0;
    /* transform: translateX(-100%); */
}

.new2leftupR {
    background-color: #0095DA;

    padding: 0.5em 2em;
    text-decoration: none;
    display: inline-block;
    border-radius: 100%;
    color: #fff;
    position: absolute;
    left: -20%;
    top: -26%;
}

.new2leftupR::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    height: 3em;
    width: 0;
}

.new2leftupL {
    background-color: #0095DA;

    padding: 0.5em 2em;
    text-decoration: none;
    display: inline-block;
    border-radius: 100%;
    color: #fff;
    position: absolute;
    left: -93%;
    top: -27%;
}

.new2leftupL::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    height: 1em;
    width: 0;
}

.new2leftupL2 {
    background-color: #0095DA;

    padding: 0.5em 2em;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    position: absolute;
    left: -88%;
    top: -12%;
}

.new2tree .new2m1 {
    position: absolute;
    background-color: #0095DA;
    
    border-radius: 10px;
    left: 46.6em;
    top: 8.3em;
}


.new2mspace {min-width: 85px;
    font-weight:bold;
    display: inline-block;
    left: 40.5em;
    position: relative;
    top: 8.3em;
}

.new2tree .new2m2 {
    color: white !important;
    background-color: #657790;
    padding: 0.5em 1em;
    border-radius: 10px;
    width: 300px;
    left: 30em;
}

.new2tree .new2m4 {
    background-color: #0095DA;
    
    border-radius: 10px;
    left: 29.6em;
}

.new2tree .new2m3 {
    color: white !important;
    background-color: #657790;
    padding: 0.5em 1em;
    border-radius: 100%;
    left: 29.5em;
    height: 66px;
}

.new2leftupL2::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 45%;
    border-left: 1px solid #333;
    height: 1em;
    width: 0;
}

.new2leftupL2::after {
    content: '';
    position: absolute;
    top: 200%;
    left: 45%;
    border-bottom: 1px solid #333;
    width: 3.8em;
    height: 0;
}

.new2leftupL3 {
    content: '';
    position: absolute;
    top: 0%;
    left: 28em;
    border-left: 1px solid #333;
    height: 16.4em;
    width: 0;
}

.new2leftupL4 {
    content: '';
    position: absolute;
    top: 54%;
    left: 28em;
    border-bottom: 1px solid #333;
    width: 14em;
    height: 0;
}

.new2leftupL5 {
    content: '';
    position: absolute;
    top: 33%;
    left: 28em;
    border-bottom: 1px solid #333;
    width: 1.5em;
    height: 0;
}

.new2leftLine3 {
    background-color: #0095DA;
    padding: 0.5em 1em; 
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    position: absolute;
    left: -40.8em;
    top: 15em;
}

.new2leftLine3::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    height: 1.8em;
    width: 0;
    /* transform: translateX(-50%); */
}

.new2leftLine4 {width: 200px;
    color: white !important;
    background-color: #657790;
    padding: 0.5em 1em;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    position: absolute;
    left: -43em;
    top: 19.4em;
}

.new2leftLine4::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 49%;
    border-left: 1px solid #333;
    height: 2em;
    width: 0;
    /* transform: translateX(-50%); */
}

.new2leftLine5 {
    background-color: #0095DA;
    padding: 0.5em 1em;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    position: absolute;
    left: -40.8em;
    top: 23em;
}

.new2leftLine5::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    height: 1.5em;
    width: 0;
    /* transform: translateX(-50%); */
}

.new2leftLine6 {width: 85px;
    height: 85px;
    color: white !important;
    background-color: #657790;
    padding:  0.5em 1em;
    text-decoration: none;
    display: inline-block;
    border-radius: 50% !important;
    color: #fff;
    position: absolute;
    left: -89.5%;
    top: 26.8em;
    height: 90px;
}

/* three phase */

/* .new2space {
    margin-top: 3%;
} */

.new2threep3 {
    /* background-color: blue; */
    /*  */
    /* padding: 0.1em 2em; */
    /* text-decoration: none; */
    /* display: inline-block; */
    /* border-radius: 50px; */
    /* color: #fff; */
    position: absolute;
    left: -100%;
    top: 5%;
}

.new2three4 {
    background-color: blue;
    
    text-decoration: none;
    display: inline-block;
    border-radius: 50px;
    color: #fff;
    position: absolute;
    left: -71%;
    top:-7.1%;
}

.new2three4::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    height: 0.3em;
    width: 0;
}

.new2threep3::before {
    content: '';
    position: absolute;
    top: -1.6em;
    left: 7.5em;
    border-bottom: 1px solid #333;
    width: 64.2em;
    height: 0
}

.new2threep3::after {
    content: '';
    position: absolute;
    top: -1.7em;
    left: 7.5em;
    border-left: 1px solid #333;
    height: 16em;
    width: 0;
    /* transform: translateX(-100%); */
}

.new2threep2 {
    background-color: #e5ac03;
    text-decoration: none;
    display: inline-block;
    border-radius: 50px;
    color: #fff;
    position: absolute;
    left: -71%;
    top: -14%;
}

.new2threep2::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    height: .5em;
    width: 0;
}

.new2threep1 {
    background-color: red;
    text-decoration: none;
    display: inline-block;
    border-radius: 50px;
    color: #fff;
    position: absolute;
    left: -71%;
    top: -21.5%;
}

.new2threep1::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    height: 1em;
    width: 0;
}

.new2threePleftupR {
    color: white !important;
    background-color: #657790;
    padding: 0.5em 1em;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    position: absolute;
    left: -47%;
    top: -50%;
}

.new2threePleftupR::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    height: 4.5em;
    width: 0;
}

.new2threePleftupL {
    color: white !important;
    background-color: #657790;
    padding: 0.5em 1em;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    position: absolute;
    left: -100%;
    top: -50%;
}

.new2threePleftupL::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    height: 1em;
    width: 0;
}

.new2threePleftupL2 {
    background-color: #0095DA;
padding: 0.5em 1em;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    position: absolute;
    left: -102%;
    top: -39%;
}

.new2threePleftupL2::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 49%;
    border-left: 1px solid #333;
    height: 1em;
    width: 0;
}

.new2threePleftupL2::after {
    content: '';
    position: absolute;
    top: 138%;
    left: 49%;
    border-bottom: 1px solid #333;
    width: 5em;
    height: 0;
}

.new2leftupN1 {
    color: white !important;
    background-color: #657790;

    padding: 0.5em 3em;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    position: absolute;
    left: -70%;
    top: -9%;
}

.new2leftupN1::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    border-bottom: 1px solid #333;
    width: 2.8em;
    height: 0;
}

.new2leftupN1::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    height: .4em;
    width: 0;
    /* transform: translateX(-100%); */
}

/* .tree li a:hover,
  .tree li a:hover+ul li a {
    background: #e9453f;
    color: #fff;
    border: 1px solid #e9453f;
  }
  
  .tree li a:hover+ul li::after,
  .tree li a:hover+ul li::before,
  .tree li a:hover+ul::before,
  .tree li a:hover+ul ul::before {
    border-color: #e9453f;
  } */


/* //rigt tree */
.newtree {
    margin: 0;
}

.newtree ul {
    position: relative;
    padding: 1em 0;
    white-space: nowrap;
    margin: 0 auto;
    text-align: center;
}

.newtree ul::after {
    content: '';
    display: table;
    clear: both;
}

.newtree li {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    list-style-type: none;
    position: relative;
    padding: 1em 0.5em 0 0.5em;
}

.newtree li::before,
.newtree li::after {
    content: '';
    position: absolute;
    top: 0;
    right: 50%;
    border-top: 1px solid #333;
    width: 50%;
    height: 1em;
}

.newtree li::after {
    right: auto;
    left: 50%;
    border-left: 1px solid #333;
}

.newtree li:only-child::after,
.newtree li:only-child::before {
    display: none;
}

.newtree li:only-child {
    padding-top: 0;
}

.newtree li:first-child::before,
.newtree li:last-child::after {
    border: 0 none;
}

.newtree li:last-child::before {
    border-right: 1px solid #333;
    border-radius: 0 5px 0 0;
}

.newtree li:first-child::after {
    border-radius: 5px 0 0 0;
}

/* .tree ul ul::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    border-left: 1px solid #333;
    width: 0;
    height: 1em;
  } */
.newtree .newstext, .newstext2.newmspace {
    font-size: small;
}
.newstext2.newmspace{top: 7em;}
.newtree .newm2.newdownLine3{top: 7em;}

.newtoptext {
    position: relative;
    font-weight:bold;
    display: inline-block;
    font-size: small;
    min-width: 120px;
    top: 5.7em;
    left: 18em;
}

.newtoptext2 {
    position: relative;
    font-weight:bold;
    display: inline-block;
    font-size: small;
    min-width: 120px;
    top: 5.6em;
    left: 18em;
}

.newrighttext {font-weight:bold;
    min-width: 82px;
    display: inline-block;
    position: relative;
    font-size: small;
    top: 10.4em;
    left: 67.6em;
}

.newrighttext2 {font-weight:bold;
    min-width: 82px;
    display: inline-block;
    position: relative;
    font-size: small;
    top: 14.5em;
    left: 49em;
}

.newrighttext3 {font-weight:bold;
    min-width: 82px;
    display: inline-block;
    position: relative;
    font-size: small;
    top: 34.5em;
    left: 33em;
}

.newrighttext4 {font-weight:bold;
    min-width: 82px;
    display: inline-block;
    position: relative;
    font-size: small;
    top: 14.5em;
    left: -0.5em;
}

/* lefttop  */
.newrighttext5 {font-weight:bold;
    min-width: 82px;
    display: inline-block;
    position: relative;
    font-size: small;
    top: -0.4em;
    left: -4.7em;
}

.newrighttext6 {font-weight:bold;
    min-width: 82px;
    display: inline-block;
    position: relative;
    font-size: small;
    top: 2em;
    left: -11em;
}

.newrighttext7 {font-weight:bold;
    min-width: 82px;
    display: inline-block;
    position: relative;
    font-size: small;
    top: 4.3em;
    left: -17.4em;
}

.newmainTitle line::before {
    content: '';
    position: absolute;
    top: 0;
    left: 70% !important;
}

.newtree li a {
    padding: 0.5em 1em;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    position: relative;
    top: 74px;
}

.newtree li .newmainTitle a {
    width: 300px;
}

.newmainTitle {
    left: -50em;
}

.newmainTitle a {
    position: absolute;
    color: white !important;
    background-color: #657790;
    right: -16.5em;
}

.newleftTitle {
    background-color: #0095DA;
    
    border-radius: 10px;
    position: relative;
    right: -17em;
}

.newleftTitle::before {
    content: '';
    position: absolute;
    top:-76%;
    left: 49%;
    border-left: 1px solid #333;
    width: 0;
    height: 2em;
}

/* up */
.newarrow::after {
    content: '';
    position: absolute;
    top: -230%;
    left: 159%;
    border-width: 6px;
    border-style: solid;
    border-color: transparent transparent black transparent;
}

/* down */
.newarrow2 {
    content: '';
    position: absolute;
    top: 34.6%;
    left: 100.3%;
    border-width: 6px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

/* left */
.newarrow3 {
    content: '';
    position: absolute;
    top: 37.6%;
    left: 47.7em;
    border-width: 6px;
    border-style: solid;
    border-color: transparent black transparent transparent;
    transform: rotate(360deg);
}


.newarrow4 {
    content: '';
    position: absolute;
    top: 35%;
    left: 155.6%;
    border-width: 6px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.newarrow5 {
    content: '';
    position: absolute;
    top: 42.7%;
    left: 37.1em;
    border-width: 6px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.newarrow6 {
    content: '';
    position: absolute;
    top: 59.6%;
    left: 37.1em;
    border-width: 6px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.newarrow7 {
    content: '';
    position: absolute;
    top: 84%;
    left: 37.1em;
    border-width: 6px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

/* right */
.newarrow8 {
    content: '';
    position: absolute;
    top: 37.8%;
    left: 26.4em;
    border-width: 6px;
    border-style: solid;
    border-color: transparent black transparent transparent;
    transform: rotate(180deg);
}

.newarrow9 {
    content: '';
    position: absolute;
    top: 22.9em;
    left: 26.3em;
    border-width: 6px;
    border-style: solid;
    border-color: transparent black transparent transparent;
    transform: rotate(180deg);
}

.newarrow10 {
    content: '';
    position: absolute;
    top: 12.6%;
    left: -64%;
    border-width: 6px;
    border-style: solid;
    border-color: transparent black transparent transparent;
    transform: rotate(180deg);
}

/* three phase */
.newthreearrow10 {
    content: '';
    position: absolute;
    top: 6.6%;
    left: -64%;
    border-width: 6px;
    border-style: solid;
    border-color: transparent black transparent transparent;
    transform: rotate(180deg);
}

.newthreearrow11 {
    content: '';
    position: absolute;
    top: 6.3%;
    left: -15.1%;
    border-width: 6px;
    border-style: solid;
    border-color: transparent black transparent transparent;
    transform: rotate(0deg);
}

.newarrow11 {
    content: '';
    position: absolute;
    top: 13.3%;
    left: -15.1%;
    border-width: 6px;
    border-style: solid;
    border-color: transparent black transparent transparent;
    transform: rotate(0deg);
}

.newarrow12 {
    content: '';
    position: absolute;
    top: 41%;
    left: -38.2%;
    border-width: 6px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.newarrow13 {
    content: '';
    position: absolute;
    top: 61%;
    left: -38.2%;
    border-width: 6px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.newrightTitle {
    background-color: #0095DA;
    
    border-radius: 10px;
    right: -17em;
}

.newrightTitle::before {
    content: '';
    position: absolute;
    top: -76%;
    left: 50%;
    border-left: 1px solid #333;
    width: 0;
    height: 2.1em;
}

.newtree .newrightLine::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    border-bottom: 1px solid #333;
    width: 2.5em;
    height: 0;
}

.newtree .newdownLine:after, .newtree .newdownLine2:after, .newtree .newdownLine3:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    width: 0;
    height: 1em;
}

.newrightbox2 {
    background-color: #0095DA;
    
    border-radius: 10px !important;
    left: 59%;
}

.newr2space {
    margin-top: -20% !important;
}

.newtree .newrightbox2::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    border-bottom: 1px solid #333;
    width: 1.7em;
    height: 0;
}


.newrightbox3 {
    background-color: #0095DA;
    padding: 0.5em 2em;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    position: absolute;
    left: 152%;
    top: 9%;
}

.newrightbox3::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    height: 1em;
    width: 0;
    /* transform: translateX(-50%); */
}

.newrightLine {
    width: 300px;
    position: absolute;
    color: white !important;
    background-color: #657790;
    right: -21.3em;
}

.newrightbox {
    color: white !important;
    background-color: #657790;
    padding: 0.5em 2em;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    position: absolute;
    left: 152%;
    top: 20.5%;
}


.newleftLine {
    background-color: #0095DA;
    padding: 0.5em 1em;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    position: absolute;
    left: 14.6em;
    top: 20%;
}

.newleftLine::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    border-bottom: 1px solid #333;
    width: 1em;
    height: 0;
}

.newleftLine2 {
    background-color: #0095DA;

    padding: 0.5em 2em;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    position: absolute;
    left: -53%;
    top: 0%;
}

.newleftLine2::before {
    content: '';
    position: absolute;
    top: 120%;
    left: 50%;
    border-bottom: 1px solid #333;
    width: 9em;
    height: 0;
}

.newleftLine2::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    height: 3.2em;
    width: 0;
    /* transform: translateX(-100%); */
}

.newleftup1 {
    color: white !important;
    background-color: #657790;

    padding: 0.5em 3em;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    position: absolute;
    left: -70%;
    top: -18%;
}

.newleftup1::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    border-bottom: 1px solid #333;
    width: 3em;
    height: 0;
}

.newleftup1::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    height: .4em;
    width: 0;
    /* transform: translateX(-100%); */
}

.newleftupR {
    background-color: #0095DA;

    padding: 0.5em 2em;
    text-decoration: none;
    display: inline-block;
    border-radius: 100%;
    color: #fff;
    position: absolute;
    left: -20%;
    top: -26%;
}

.newleftupR::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    height: 3em;
    width: 0;
}

.newleftupL {
    background-color: #0095DA;

    padding: 0.5em 2em;
    text-decoration: none;
    display: inline-block;
    border-radius: 100%;
    color: #fff;
    position: absolute;
    left: -93%;
    top: -27%;
}

.newleftupL::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    height: 1em;
    width: 0;
}

.newleftupL2 {
    background-color: #0095DA;

    padding: 0.5em 2em;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    position: absolute;
    left: -88%;
    top: -12%;
}

.newtree .newm1 {
    position: absolute;
    background-color: #0095DA;
    
    border-radius: 10px;
    left: 31.3em;
    top: 6.2em;
}

.newtree .newm1.newdownLine2{top: 7.2em;}

.newmspace {min-width: 85px;
    font-weight:bold;
    display: inline-block;
    left: 25.7em;
    position: relative;
    top: 5.9em;
}

.newtree .newm2 {
    width: 300px;
    color: white !important;
    background-color: #657790;
    padding: 0.5em 1em;
    border-radius: 10px;
    top: 88px;
    left: 16.5em;
}

.newtree .newm4 {
    background-color: #0095DA;
    border-radius: 10px;
    left: 16.4em;
    top: 95px;
}

.newtree .newm3 {width: 85px;
    height: 85px;
    color: white !important;
    background-color: #657790;
    padding: 0.5em 1em;
    border-radius: 100%;
    left: 33.5em;top: 95px;
    display: table-cell;
    vertical-align: middle;
}

.newleftupL2::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 45%;
    border-left: 1px solid #333;
    height: 1em;
    width: 0;
}

.newleftupL2::after {
    content: '';
    position: absolute;
    top: 200%;
    left: 45%;
    border-bottom: 1px solid #333;
    width: 3.8em;
    height: 0;
}



.newleftLine3 {
    background-color: #0095DA;

    padding: 0.5em 2em;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    position: absolute;
    left: -53%;
    top: 18%;
}

.newleftLine3::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    height: 1.8em;
    width: 0;
    /* transform: translateX(-50%); */
}

.newleftLine4 {
    color: white !important;
    background-color: #657790;

    padding: 0.5em 4em;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    position: absolute;
    left: -70%;
    top: 31%;
}

.newleftLine4::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 52%;
    border-left: 1px solid #333;
    height: 2em;
    width: 0;
    /* transform: translateX(-50%); */
}

.newleftLine5 {
    background-color: #0095DA;

    padding: 0.5em 2em;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    position: absolute;
    left: -53%;
    top: 45%;
}

.newleftLine5::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    height: 1.5em;
    width: 0;
    /* transform: translateX(-50%); */
}

.newleftLine6 {
    color: white !important;
    background-color: #657790;

    padding: 0.5em 2em;
    text-decoration: none;
    display: inline-block;
    border-radius: 50% !important;
    color: #fff;
    position: absolute;
    left: -54%;
    top: 57%;
}

/* three phase */

.newspace {
    margin-top: 5%;
}

.newthreep3 {
    background-color: blue;

    padding: 0.1em 2em;
    text-decoration: none;
    display: inline-block;
    border-radius: 50px;
    color: #fff;
    position: absolute;
    left: -53%;
    top: 3%;
}

.newthreep3::before {
    content: '';
    position: absolute;
    top: 120%;
    left: 50%;
    border-bottom: 1px solid #333;
    width: 9em;
    height: 0;
}

.newthreep3::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    height: 3.2em;
    width: 0;
    /* transform: translateX(-100%); */
}

.newthreep2 {
    background-color: #e5ac03;
    ;

    padding: 0.1em 2em;
    text-decoration: none;
    display: inline-block;
    border-radius: 50px;
    color: #fff;
    position: absolute;
    left: -53%;
    top: -3%;
}

.newthreep2::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    height: .4em;
    width: 0;
}

.newthreep1 {
    background-color: red;

    padding: 0.1em 2em;
    text-decoration: none;
    display: inline-block;
    border-radius: 50px;
    color: #fff;
    position: absolute;
    left: -53%;
    top: -9%;
}

.newthreep1::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    height: .3em;
    width: 0;
}

.newthreePleftupR {
    color: white !important;
    background-color: #657790;

    padding: 0.5em 2em;
    text-decoration: none;
    display: inline-block;
    border-radius: 100%;
    color: #fff;
    position: absolute;
    left: -20%;
    top: -35%;
}

.newthreePleftupR::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    height: 3em;
    width: 0;
}

.newthreePleftupL {
    color: white !important;
    background-color: #657790;

    padding: 0.5em 2em;
    text-decoration: none;
    display: inline-block;
    border-radius: 100%;
    color: #fff;
    position: absolute;
    left: -93%;
    top: -35%;
}

.newthreePleftupL::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    height: 1em;
    width: 0;
}

.newthreePleftupL2 {
    background-color: #0095DA;

    padding: 0.5em 2em;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    position: absolute;
    left: -88%;
    top: -20%;
}

.newthreePleftupL2::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 45%;
    border-left: 1px solid #333;
    height: 1em;
    width: 0;
}

.newthreePleftupL2::after {
    content: '';
    position: absolute;
    top: 200%;
    left: 45%;
    border-bottom: 1px solid #333;
    width: 3.8em;
    height: 0;
}

.newleftupN1 {
    color: white !important;
    background-color: #657790;

    padding: 0.5em 3em;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    position: absolute;
    left: -70%;
    top: -9%;
}

.newleftupN1::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    border-bottom: 1px solid #333;
    width: 2.8em;
    height: 0;
}

.newleftupN1::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    height: .4em;
    width: 0;
    /* transform: translateX(-100%); */
}

.newleftupL3 {
    content: '';
    position: absolute;
    top: 16.5em;
    left: 15em;
    border-left: 1px solid #333;
    height: 7em;
    width: 0;
}

.newleftupL4 {
    content: '';
    position: absolute;
    top: 16.5em;
    left: 7.9em;
    border-bottom: 1px solid #333;
    width: 8em;
    height: 0;
}

.newleftupL5 {
    content: '';
    position: absolute;
    top: 23.3em;
    left: 15em;
    border-bottom: 1px solid #333;
    width: 12em;
    height: 0;
}

.smallDataWidth {
    min-width: 88px;
    min-height: 25px;
}

.bigDataWidth {
    min-width: 135px;
    min-height: 36.6px;
}

/* .tree li a:hover,
  .tree li a:hover+ul li a {
    background: #e9453f;
    color: #fff;
    border: 1px solid #e9453f;
  }
  
  .tree li a:hover+ul li::after,
  .tree li a:hover+ul li::before,
  .tree li a:hover+ul::before,
  .tree li a:hover+ul ul::before {
    border-color: #e9453f;
  } */

/* Media query for smaller screens */
@media (max-width: 1600px) {
    .newrighttext5 {top: 0.2em;} 
    .newrighttext6{top: 2.7em;}
    .newrighttext7{top: 4.8em;}
    .newspace {margin-top: 7%;}
   
    .new2tree {margin: -52% 0 0 48%;}
    .sidebar-collapse .new2tree {
        margin: 4.8% 0 0 0;
    }
    .newarrow4 {left: 155.8%;}
}

@media (max-width: 1366px) {
    /* .newspace {
        margin-top: 14%;
    }
    .new2space {
        margin-top: -63.3%;
    margin-left: 59.5%;
    } 
    .sidebar-collapse .new2space {
        margin-top: -54%;
    margin-left: 49.5%;
    }  */
    .sidebar-collapse .new2tree {margin: 4.8% 0 0 0;}
    .new2tree {margin: -55% 0 0 56%;}
    .new2leftLine3 {left: -46.8em;}
    .new2leftLine4 {left: -49.4em;}
    .new2threePleftupL2 {top: -40%;}
    .new2leftLine5 {left: -46.8em;}
    .new2threePleftupL {left: -98.5%;}
    .new2threePleftupR::before {height: 4.1em;}
    .new2threep1 {top: -24%;}
    .new2threep2 {top: -16%;}
    .new2three4 {top: -8.1%;}
    .newrighttext5 {top: -0.7em; left: -5.3em;}
    .newrighttext6{top: 1.4em;left: -11.3em;}
    .newrighttext7{top: 3.7em;left: -17.6em;}
    .sidebar-collapse .newrighttext5{left:-3.3em;}
    .sidebar-collapse .newrighttext6{left:-9.3em;}
    .sidebar-collapse .newrighttext7{left: -15.6em;}
    .newarrow2 {top: 35.6%; left: 95.6%;}
    .newarrow3 {top: 38.6%; left: 53.2em;}
    .newarrow4 {top: 35.1%;left: 148%;}
    .newarrow5 {top: 43.7%; left: 40.7em;}
    .newarrow6 {top: 59.6%; left: 40.5em;}
    .newarrow7 {left: 40.4em;}
    .newarrow8 {top: 38.3%; left: 28.1em;}
    .newarrow9 {top: 23em; left: 27.9em;}
    .newleftupL3 {left: 15.5em; top: 17em; height: 6.5em;}
    .newleftupL4 {top: 17em; left: 8em; width: 8.4em;}
    .sidebar-collapse .newleftupL4 {top: 17em; left: 9.5em;width: 8em;}
    .newleftupL5 {top: 23.4em; left: 15.5em; width: 12.7em;}
    .newrighttext {font-size: 11px;left: 74.9em;}
    .newrighttext2 {font-size: 11px; top: 15.5em;left: 56em;}
    .newrighttext4 {font-size: 11px; top: 15.5em; left: -2.5em;}
    .newtree .newm1.newdownLine2 {top: 7.8em;}
    .newtree .newm2.newdownLine3 {top: 7.5em;}
    .newtree .newm4 {top: 90px;}
    .newtree .newm3 {left: 35.8em; top: 92px;}
    .newrightbox2 {left: 54%;}
    .newrightbox {left: 147%; top: 21.5%;}
    .newrightbox3 {left: 144%;}
    .newtree .newm1 {top: 7.2em;left: 34em;}
    .new2leftLine6 {left: -91.5%; height: 79px;}
    .new2threePleftupR {left: -46%;}
    .newtree .newm2 {top: 80px;}
    .newleftLine {left: 14.5em; top: 22%;}
    .newrightLine {right: -22.3em;}
    .bigDataWidth {min-height: 30px;}
    .newleftTitle {right: -17.5em;}
    .newrightTitle {right: -17.5em;}
    .newtoptext {left: 15.5em;}
    .newtoptext2 {left: 16.5em;}
    .new2leftup1 {top: -33%;}
    .new2leftLine {left: 31.5em; top: 31%;}
    .new2rightLine {right: -32em;}
    .new2rightbox3 {left: 170%;}
    .new2tree li a {top: 103px;}
    .new2threep3::before {width: 71.2em;}
    .new2leftupL3 {left: 30em; height: 17em;}
    .new2leftupL4 {left: 30em; width:15em; top:57%;}
    .new2leftupL5 {top: 34%; left: 30em;}
    .new2righttext {font-size: 11px;left: 77.7em;}
    .new2righttext2 {font-size: 11px;left: 57.5em;}
    .new2righttext4 {font-size: 11px;top: 17.6em;}
    .new2toptext {font-size: 11px; top: 8.8em; left: 36em;}
    .new2toptext2{font-size: 11px; top: 8.6em; left: 37em;}
    .new2threearrow10 {top: -0.9%; left: -75.9%;}
    .new2threearrow11 {top: -1.4%; left: -43%;}
    .new2arrow12 {top: 27.5em; left: -40.6em;}
    .new2arrow13 {top: 34.8em; left: -40.6em;}
    .new2rightTitle, .new2leftTitle {right: -33em;}
    .new2mainTitle a {right: -33em;}
    .new2tree .new2m1 {left: 51em; top:9.2em;}
    .new2tree .new2m2 {left: 33em;}
    .new2tree .new2stext{font-size: 11px;}
    .new2mspace {left: 45.5em; top: 9em;}
    .new2rightbox {left: 172%; top: 31.5%;}
    .new2tree .new2m4 {left: 32.3em;}
    .new2tree .new2m3 {left: 32em; height: 58px;}
    .new2arrow2 {top: 43.3%; left: 125.3%;}
    .new2arrow3 {top: 46.7%; left: 69.9em;}
    .new2arrow4 {top: 43%; left: 175.5%;}
    .new2arrow5 {top: 51.3%; left: 57.1em;}
    .new2arrow6 {top: 66.3%; left: 57.3em;}
    .new2arrow7 {top: 90.6%; left: 57.1em;}
    .new2arrow8 {top: 45.9%; left: 45em;}
    .new2arrow9 {top: 25em; left: 45.3em;}
    .newrighttext3 {top: 30.5em;}
    .new2righttext3 {font-size: 12px; top: 34.3em; left: 34.3em;}
    .newarrow::after {top: -235%; left: 162%;}
    
}

@media (max-width: 1280px) {
    .umhc .mdlContent.card-body {
        overflow-x: scroll;
    }
    .newspace {
        margin-top: 8%;
    }
    .new2tree {
        margin: -60% 0 0 61%;
    }
    .sidebar-collapse .new2tree {
        margin: -50.2% 0 0 53%;
    }
    /* .new2space {
        margin-top: -59.8%;
        margin-left: 61.5%;
    } */
}

@media (max-width: 1024px) {
    .sidebar-collapse .new2tree {
        margin: -65.2% 0 0 68%;
    }
    .new2tree {
        margin: -83% 0 0 84%;
    }
    .newspace {
        margin-top: 10%;
    }
    /* .newspace {
        margin-top: -5%;
    } */
    /* .new2space {
    margin-top: -74.8%;
    margin-left: 70.5%;
}  */
}

@media (max-width: 912px) {
    .sidebar-collapse .new2tree {
        margin: -73% 0 0 78%;
    }
    .new2tree {
        margin: -72% 0 0 76%;
    }
    /* .new2space {
        margin-top: -84.8%;
        margin-left: 80.5%;
    } */
    .newrighttext5 {top: 0.1em;left: -5.4em;}
    .newrighttext6{top: 2.5em; left: -11.7em;}
    .newrighttext7{top: 4.7em; left: -18.1em;}
}

@media (max-width: 820px) {
    .sidebar-collapse .new2tree {
        margin: -83% 0 0 89%;
    }
    .newspace {
        margin-top: 12%;
    }
    .new2tree {
        margin: -82% 0 0 86%;
    }
    /* .new2space {
        margin-top: -96.8%;
        margin-left: 91.5%;
    }  */
}


@media (max-width: 768px) {
    .sidebar-collapse .new2tree {
        margin: -90% 0 0 97%;
    }
    .new2tree {
        margin: -89% 0 0 93%;
    }
}

@media (max-width: 663px) {
    .sidebar-collapse .new2tree {
        margin: -112% 0 0 117%;
    }
    .new2tree {
        margin: -110% 0 0 111%;
    }
    .newspace {
        margin-top: 16%;
    }

    /* .new2space {
        margin-top: -130.8%;
        margin-left: 119.5%;
    } */

}
@media (max-width: 600px) {
    .sidebar-collapse .new2tree {
        margin: -128% 0 0 133%;
    }
    .new2tree {
        margin: -126% 0 0 127%;
    }
    /* .newspace {
        margin-top: 17%;
    }
    .new2space {
        margin-top: -148.8%;
    margin-left: 136.5%;
    } */

}

@media (max-width: 540px) {
    .sidebar-collapse .new2tree {
        margin: -147% 0 0 153%;
    }
    .new2tree {
        margin: -145% 0 0 146%;
    }
    /* .newspace {
        margin-top: 20%;
    }
    .new2space {
        margin-top: -171.8%;
    margin-left: 158.5%;
    } */

}
@media (max-width: 430px) {
    .sidebar-collapse .new2tree {
        margin: -205% 0 0 213%;
    }
    .newspace {
        margin-top: 20%;
    }
    /* .newspace {
        margin-top: 27%;
    }
    .new2space {margin-top: -238.8%;
        margin-left: 220.5%;
    } */
}
@media (max-width: 414px) {
    .sidebar-collapse .new2tree {
        margin: -217% 0 0 227%;
    }
    /* .newspace {
        margin-top: 27%;
    }
    .new2space {
        margin-top: -255.8%;
    margin-left: 235.5%;
    } */
}

@media (max-width: 390px) {
    .sidebar-collapse .new2tree {
        margin: -239% 0 0 257%;
    }
    .newspace {
        margin-top: 34%;
    }
    .new2space {margin-top: -280%;
        margin-left: 256.5%;
    }
}

@media (max-width: 375px) {
    .sidebar-collapse .new2tree {
        margin: -256% 0 0 266%;
    }
    .new2space {
        margin-top: -297%;
        margin-left: 273.5%;
    }
}