a[href^="https://maps.google.com/maps"]{display:none !important}
a[href^="https://maps.google.com/maps"]{display:none !important}

.gmnoprint a, .gmnoprint span, .gm-style-cc, .gm-style-iw-chr {
    display:none;
}
.gm-style-iw.gm-style-iw-c {
    padding-top: 10px !important;
}

.gmnoprint div {
    background:none !important;
}