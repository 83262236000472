@media print {
  .page-main {
    padding-top: 50px;
    padding-bottom: 50px;
    display: block;
  }
  .watermark {
    position: fixed;
    top: 50vh;
    z-index: 9;
    width: 50vw;
    page-break-after: always;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.2;
  }
}
.info-box{min-height: 40px!important;}
.info-box .info-box-icon{width: 30px!important;}
.bg-greenPin {
  background-color: #92D050 !important;
}
.bg-redPin {
  background-color: #FF0000 !important;
}
.bg-grayPin {
  background-color: #657790 !important;
}
/* .info-box .info-box-text{font-size: 16px!important; font-weight: 700!important;} */


/* background color for status */

.custom-cell.active {
  background: #92D050;
  padding: 3px; border-radius: 5px; color: #fff; font-weight: 500;
}

.custom-cell.Active {
  display: inline-block;
  background: #92D050;
}

.custom-cell.inactive {
  background: #657790;
  padding: 3px; border-radius: 5px; color: #fff; font-weight: 500;
}

.custom-cell.Inactive {
  display: inline-block;
  background: #657790;
}