/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap"); */
@import url("../fonts/style.css");

body {
  font-family: "Montserrat", sans-serif !important;
  color: #000 !important;
  font-size: 0.875rem;
  background: #f5f5fa;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

#myChart .apexcharts-toolbar {
  display: none !important; /* Hides the toolbar for this specific chart */
}
.apexcharts-menu-icon {
  padding-left: 10px;
}
.table-height {
  height: 402px;
  margin-bottom: 0;
}

.custom-tooltip {
  text-align: center;
}
ul {
  list-style-type: none;
}

a:hover {
  text-decoration: none;
}

.actionWpr a {
  color: #484a4c;
}

.actionWpr a.btn-c-outline-primary {
  color: #0095da;
}

.actionWpr a.btn-c-outline-primary:hover {
  color: #fff;
}

.h2,
h2 {
  font-size: 1.75rem;
  margin: 0;
  font-weight: 500;
  color: #000;
}
.h3,
h3 {
  color: #000;
}

.h5,
h5 {
  font-size: 1rem;
  margin: 0;
  font-weight: 600;
  color: #000;
}

.h6,
h6 {
  font-weight: 600;
}

.login-section {
  background: #fff;
  padding: 0;
  height: 100vh;
}

.login-section .login-row {
  height: 100vh;
  position: relative;
}
.login-section .avatar-bg {
  position: relative;
}
.login-section .avatar-bg::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background: url(../images/login-bg.png) no-repeat center center;
  background-size: cover;
  width: 50%;
  height: 100%;
  z-index: 9;
}
.login-section .avatar-bg .avatar-img {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 20px;
  opacity: 70%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
  z-index: 99;
}
.login-section .avatar-bg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-bg-inner {
  vertical-align: middle;
}

.login-section .avatar-wrap {
  text-align: center;
  margin: 0px 0 50px 0;
}

.login-section .iotImg-wrap {
  text-align: center;
}

.login-section .iotImg-wrap img {
  width: 100%;
}

.login-section .login-form-inner-head h5 {
  margin-top: 10px;
  color: #657790;
}

.login-form-group label {
  display: block;
  width: 100%;
  color: #484a4c;
  margin-bottom: 5px;
}

.input-group-text {
  background-color: #fff;
  border-radius: 0 0.375rem 0.375rem 0;
}

.login-section .login-form-inner-head {
  text-align: center;
  margin-bottom: 50px;
}

.login-section .login-form-inner-head img {
  width: 140px;
}

.login-section .login-form-inner {
  max-width: 400px;
  margin: 0 auto;
}

.login-section .input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.login-section .notFound .login-form-inner {
  max-width: 300px;
  margin: 0 auto;
}

.login-section .notFound .login-form-inner h2 {
  font-size: 6.25rem;
  margin-bottom: 20px;
}

.login-section .notFound .login-form-inner h3 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #657790;
}

.login-section .notFound .login-form-inner h4 {
  font-size: 2rem;
  margin-bottom: 40px;
  color: #657790;
}

.map .pr-card .card-body {
  padding: 5px;
}

.form-group {
  margin-bottom: 1rem;
}

a {
  text-decoration: none;
}

.cGTffh {
  font-size: 0.813rem;
  font-weight: 600;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #0095da;
  border: 1px solid transparent;

  box-shadow: 0px 2px 14px rgba(19, 170, 240, 0.42);
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.btn-c-outline-secondary {
  color: #657790;
  text-align: center;
  background: #ffff;
  border: 0.5px solid #657790;
}

.btn-c-outline-secondary:hover,
.btn-c-outline-secondary:active,
.btn-c-outline-secondary:focus {
  background: #e5e8f0;
  color: #657790;
}

input[type="button"].btn-block,
input[type="reset"].btn-block,
input[type="submit"].btn-block {
  width: 100%;
}

.navbar {
  padding: 0;
}

.navbar-white {
  background-color: #ffffff;
  border-bottom: 1px solid #dee2e6;
}

.navbar-brand {
  padding: 10px;
}

.nav-item {
  font-size: 0.813rem;
}

.nav-pills .nav-link {
  border-radius: 15px;
  padding: 8px 15px;
  margin: 5px 0;
}

.nav-pills .nav-link.active {
  background-color: #0095da;
}

.sidebar-mini .main-sidebar .nav-link {
  width: 165px;
}
.apexcharts-xaxis-label {
  font-family: "Montserrat", sans-serif !important;
  font-size: 0.688rem !important;
  font-weight: 500 !important;
}
.apexcharts-legend-text {
  font-family: "Montserrat", sans-serif !important;
  font-size: 0.813rem !important;
  font-weight: 500 !important;
}
.apexcharts-yaxis-label {
  font-family: "Montserrat", sans-serif !important;
  font-size: 0.688rem !important;
  font-weight: 500 !important;
}
.apexcharts-yaxis-title-text {
  font-family: "Montserrat", sans-serif !important;
  font-size: 0.813rem !important;
  font-weight: 500 !important;
}
.apexcharts-xaxis-title-text {
  font-family: "Montserrat", sans-serif !important;
  font-size: 0.813rem !important;
  font-weight: 500 !important;
}

.nav-sidebar .nav-link p {
  color: #000;
  cursor: pointer;
}
.nav-sidebar .nav-link.active p {
  color: #fff;
}

.nav-link.active {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5;
  white-space: normal;
  word-wrap: break-word;
}

.navbar-brand .brand-image {
  width: 50px;
}

.pr-card .breadcrumbHdr.card-header {
  padding: 0;
  background: #e0e4e9;
  border-radius: 20px 20px 0 0;
  border-bottom: 1px solid #f5f5fa;
}

.pr-card .breadcrumbHdr.card-header .breadcrumb {
  background: transparent;
  margin-bottom: 0;
  font-size: 0.938rem;
}
.breadcrumb{font-size: 0.938rem; border-radius: 20px 20px 0 0;}

.kvgeKk,
.breadcrumb {
  --bs-breadcrumb-item-active-color: #000;
}

.breadcrumb a {
  color: #000;
  font-weight: 560;
}

.kvgeKk {
  min-height: 38px !important;
}

.jNvkxB {
  white-space: break-spaces !important;
}

.pr-card .card-header,
.pr-card .card-footer {
  border: 0px;
  background: #fff;
  padding: 20px;
}

.pr-card .card-body {
  padding: 0 20px 20px 20px;
}

a {
  color: #0095da;
}

a.btn-c-outline-primary:hover {
  text-decoration: none;
}

.btn-c-outline-primary,
.input-group .btn:focus {
  color: #0095da;
  text-align: center;
  background: #fff;
  border: 1px solid #0095da;
}

.btn {
  font-size: 0.813rem;
  border-radius: 4px;
  transition: all 0.2s linear all;
  text-transform: capitalize;
  padding: 7px 20px;
  letter-spacing: 1px;
}

.btn-c-outline-primary:hover {
  background: #0095da;
  border: 1px solid #0095da;
  color: #fff;
}

.btn-secondary {
  color: #657790;
  background-color: #eff1f6;
  border-color: #eff1f6;
  box-shadow: none;
}

.btn-secondary:hover {
  color: #657790;
  background-color: #e5e8f0;
  border-color: #e5e8f0;
}

.upload_label {
  border-left: 1px solid #ccc;
  padding-left: 0.5rem;
  cursor: pointer;
  float: right;
}
.react-datepicker {
  margin-left: 104px;
}
.uploadWpr .fa {
  font-size: 1.5rem;
}
.custom-cell {
  font-size: 0.875rem;
  font-weight: 500;
}
.hSQKHD {
  font-size: 0.875rem !important;
  font-weight: 500 !important; /* Add !important */
  color: rgba(0, 0, 0, 0.87);
}

.kzZBXj {
  overflow: hidden;
  white-space: unset !important;
  text-overflow: ellipsis;
  font-size: 0.938rem;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
}

.upload-btn-wrapper input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.mb0 {
  margin-bottom: 0;
}
.mb20 {
  margin-bottom: 20px;
}

.boldText {
  font-weight: bold;
}

.mt20 {
  margin-top: 20px;
}

.mt27 {
  margin-top: 27px;
}

.mr10 {
  margin-right: 10px;
}

.mt10 {
  margin-top: 10px;
}

hr {
  margin-top: 5px;
  margin-bottom: 5px;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.5);
}

.hqyqtU,
.iHSyYe {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.iHSyYe {
  line-height: inherit !important;
}

.main-header .nav-link.btn.btn-primary {
  height: 31.5px;
  margin-top: 5px;
  color: #fff;
}

.custNameBtn .btn-secondary,
.custNameBtn .btn-secondary:hover {
  color: #ffffff;
  background-color: #5a6268;
  border-color: #545b62;
  font-weight: 500;
}

.container {
  max-width: 100%;
}

.content-wrapper {
  padding-top: 55px;
  height: auto;
  min-height: 100vh !important;
}

.pageTitle {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 20px;
}

.content-header .breadcrumb {
  background: transparent;
  line-height: 1.2rem;
  margin-bottom: 0;
  padding: 0;
}

.breadcrumb li:last-child {
}

.table-responsive {
  overflow-x: scroll;
}

.float-right {
  float: right !important;
}

.card {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  margin-bottom: 1rem;
}

.status_icon {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
}

.green {
  background-color: #92d050;
}

.gray {
  background-color: #e0e4e9;
}

.map iframe {
  height: 300px;
}

.modal-lg,
.modal-xl {
  max-width: 1100px;
}

.modal-content {
  border-radius: 20px;
}

.modal .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.5;
  border: none;
  background: no-repeat;
}

.right.modal .modal-header {
  background: #e0e4e9;
  border-bottom: 1px solid #ccc;
}

.right.modal .modal-header .h5,
.right.modal .modal-header h5 {
  font-weight: 500;
}

label:not(.form-check-label):not(.custom-file-label),
.dataTables_info,
.page-item.disabled .page-link,
.page-item.active .page-link {
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: 0.813rem;
  line-height: 14px;
  font-weight: 600;
}

.selectWpr {
  position: relative;
  z-index: 6;
}

.dataTables_info {
  line-height: 30px;
}

.form-control {
  font-weight: 500;
  color: #000;
  font-size: 0.875rem;
}

.readOnly p {
  line-height: 1.5;
  font-size: 0.875rem;
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
}

.btn-container {
  border: 1px dashed #aaa;
  border-radius: 20px;
  padding: 20px;
}

.actionWpr .upload-btn-wrapper .btn,
.actionWpr a {
  padding: 1px 7px;
}

.actionWpr a {
  vertical-align: top;
}

.actionWpr .dropdown-menu .dropdown-item {
  font-size: 0.875rem;
}

.actionWpr .dropdown-menu a:hover {
  text-decoration: none;
}

#modal_map iframe {
  height: 425px;
}

#editDevice #modal_map iframe {
  height: 330px;
}

.files input {
  outline: 2px dashed #92b0b3;

  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  padding: 60px 0px 55px 30%;
  text-align: center !important;
  margin: 0;
  width: 100% !important;
}

.files input:focus {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;

  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  border: 1px solid #92b0b3;
}

.files {
  position: relative;
}

.files:after {
  pointer-events: none;
  position: absolute;
  top: 50px;
  left: 0;
  width: 50px;
  right: 0;
  height: 50px;
  content: "";
  background-image: url(https://image.flaticon.com/icons/png/128/109/109612.png);
  display: block;
  margin: 0 auto;
  background-size: 100%;
  background-repeat: no-repeat;
}

.color input {
  background-color: #f1f1f1;
}

.files:before {
  position: absolute;
  top: 20px;
  left: 0;
  pointer-events: none;
  width: 100%;
  right: 0;
  height: 57px;
  content: "Drop file here Or";
  display: block;
  margin: 0 auto;
  color: #212529;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
}

.content-header {
  padding: 10px 20px;
  margin-bottom: 20px;
  background: #fcfdfd;
  border-radius: 0 0 10px 10px;

  position: sticky;
  top: 55px;
  display: block;
  z-index: 1030;
}

.dataTables_length label {
  margin-bottom: 0;
  display: inline-block;
}

.dataTables_length select {
  width: auto;
  display: inline-block;
}

.dataTables_paginate .pagination,
.dataTables_filter {
  justify-content: flex-end;
}

.dataTables_filter input[type="checkbox"] {
  width: 30px;
  height: 20px;
  padding: 0.25rem 0.5rem;
  border-radius: 0.2rem;
  border: 1px solid #ced4da;
}

.dataTables_filter .fa {
  font-size: 20px;
  line-height: 30px;
}

:not(.layout-fixed) .main-sidebar {
  background: #f5f5fa;
  margin-top: 57px;
}

body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav)
  .main-header {
  margin-left: 70px;
}

.nav-pills .nav-link:not(.active):hover {
  font-weight: 600;
  background-color: #e0e4e9;
  color: #484a4c;
}

.sidebar-mini.sidebar-collapse .main-header {
  margin-left: 70px !important;
}

.main-header {
  padding: 0px 10px 0px 0;
  border-bottom: 0;
  background: #f5f5fa;
}

.brand-link {
  height: 57px;
  color: #484a4c;
}

.brand-link:hover {
  color: #484a4c;
}

.brand-link .brand-image {
  margin-left: 0;
  max-height: 35px;
}

.sidebarLeft {
  z-index: 1040;
  position: fixed;
  padding-top: 10px;
  background: #e0e4e9;
  float: left;
  /* width: auto; */
  width: 59px;
  height: 100vh;
}

body:not(.sidebar-mini-md) .historicalPage.content-wrapper {
  margin-right:0px;
}
.sidebar-mini.sidebar-collapse .historicalPage.content-wrapper {
  margin-left: 5.5rem !important;
}

.sidebarLeft .nav-link {
  text-align: center;
  cursor: pointer;
}

.sidebarLeft .nav-link span {
  word-break: break-all;
  white-space: break-spaces;
}

.sidebarLeft .dropright .dropdown-menu {
  border-radius: 15px;
  padding: 10px;
  font-size: 0.875rem;
  margin: 0;
}

.sidebarLeft .dropright .dropdown-menu .dropdown-item {
  cursor: pointer;
}

.sidebarLeft .dropright .dropdown-menu.dropdown-item.active {
  border-radius: 15px;
}

.sidebarLeft .dropright .dropdown-menu .dropdown-item.active,
.sidebarLeft .dropright .dropdown-menu .dropdown-item:hover,
.sidebarLeft .dropright .dropdown-menu .dropdown-item:focus {
  border-radius: 15px;
}

.sidebarLeft .fa,
.sidebarRight .fa {
  font-size: 20px;
  padding: 7px;
  margin-bottom: 5px;
}

.sidebarRight .fa {
  color: #0095da;
}

.sidebarLeft .fa:hover,
.sidebarRight .fa:hover {
  background-color: #f5f5fa;
  border-radius: 20px;
}

.clicked {
  background-color: #f5f5fa;
  border-radius: 20px;
}

.nav-link {
  color: #000;
  padding: 0.5rem;
  font-size: 0.875rem;
  cursor: pointer;
  font-weight: 500;
  white-space: normal;
  word-wrap: break-word;
}

.sidebar-mini .nav-sidebar {
  white-space: inherit;
}

.nav-sidebar > .nav-item .nav-icon {
  width: auto;
}

.nav-sidebar > .nav-item .nav-icon.fa {
  font-size: 1rem;
}

.dropright .dropdown-toggle::after {
  display: none;
}

:not(.layout-fixed) .main-sidebar .sidebar {
  display: flex;
  padding-left: 75px;
}

.selco {
  background-image: url(../images/solar.png);
  background-size: 25px;
  background-position: center;
  background-repeat: no-repeat;
  height: 25px;
}

.other {
  background-image: url(../images/other.png);
  background-size: 25px;
  background-position: center;
  background-repeat: no-repeat;
  height: 25px;
}

.ups {
  background-image: url(../images/ups.png);
  background-size: 24px;
  background-position: center;
  background-repeat: no-repeat;
  height: 25px;
}

.transformer {
  background-image: url(../images/transformer.png);
  background-size: 24px;
  background-position: center;
  background-repeat: no-repeat;
  height: 25px;
}

.motor {
  background-image: url(../images/motor.png);
  background-size: 24px;
  background-position: center;
  background-repeat: no-repeat;
  height: 25px;
}

.hvac {
  background-image: url(../images/chiller.png);
  background-size: 24px;
  background-position: center;
  background-repeat: no-repeat;
  height: 25px;
}

.ems {
  background-image: url(../images/ems.png);
  background-size: 25px;
  background-position: center;
  background-repeat: no-repeat;
  height: 25px;
}

.d3Ems {
  background-image: url(../images/electric-meter\ new.png);
  background-size: 34px;
  background-position: center;
  background-repeat: no-repeat;
  height: 35px;
}

.solar {
  background-image: url(../images/solar.png);
  background-size: 24px;
  background-position: center;
  background-repeat: no-repeat;
  height: 25px;
}

.aqiq {
  background-image: url(../images/aqi.png);
  background-size: 30px;
  background-position: center;
  background-repeat: no-repeat;
  height: 25px;
}

.cable {
  background-image: url(../images/cable.png);
  background-size: 25px;
  background-position: center;
  background-repeat: no-repeat;
  height: 25px;
}

.asset {
  background-image: url(../images/transformer.png);
  background-size: 25px;
  background-position: center;
  background-repeat: no-repeat;
  height: 25px;
}

.user {
  background-image: url(../images/user.png);
  background-size: 30px;
  background-position: center;
  background-repeat: no-repeat;
  height: 30px;
}
.assetAllo {
  background-image: url(../images/transformer.png);
  background-size: 25px;
  background-position: center;
  background-repeat: no-repeat;
  height: 25px;
}

.historical {
  background-image: url(../images/historical.png);
  background-size: 25px;
  background-position: center;
  background-repeat: no-repeat;
  height: 25px;
}

ul {
  padding: 0;
  margin: 0;
}

.sidebar-mini.sidebar-collapse .main-sidebar:hover {
  background: #fff;
}

.sidebarRight {
  bottom: 0;
  min-height: 100%;
  overflow-y: hidden;
  width: 45px;
  z-index: 1038;
  float: none;
  height: 100vh;
  right: 0;
  position: fixed;
  top: 57px;
}

body:not(.sidebar-mini-md) .content-wrapper,
body:not(.sidebar-mini-md) .main-footer {
  margin-right: 45px;
}

.sidebar-mini.sidebar-collapse .content-wrapper {
  margin-left: 4.1rem !important;
}

.main-header {
  z-index: 1040;
}

.navbar-expand .navbar-nav .dropdown-menu {
  right: 0;
  left: auto;
}

.sidebarRight .nav-link {
  padding: 0;
}

.sidebarRight .nav-item {
  text-align: center;
}

.content-wrapper > .content {
  padding: 0;
}

.mdlContent.card {
  border-radius: 30px;
  box-shadow: none;
  height: calc(100vh - (4.5rem + 1px));
}

.mdlContent.card-body {
  background: #fff;
  border-radius: 20px;
  padding: 20px 20px;
  overflow-y: scroll;
}

.table thead th {
  font-size: 0.813rem;
  line-height: 18px;
}

.table td {
  font-size: 0.875rem;
  font-weight: 500;
}

.modal.right.fade .modal-dialog {
  right: -320px;

  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right .modal-dialog {
  margin: auto;
  width: 30%;
  height: 100%;
}

.modal.right.fade .modal-dialog,
.modal.rightGroup.fade .modal-dialog {
  right: 0;
  position: fixed;
}

.modal.rightGroup.fade .modal-dialog {
  width: 30%;
  top: 0;
  bottom: 0;
}

.modal.right.fade .modal-content {
  height: 100%;
  border-radius: 0;
}

.modal.right .modal-body {
  max-height: calc(100% - 120px);
  overflow-y: scroll;
}

.modal.right .modal-body .fa {
  font-size: 20px;
}

.modal.right .btn-sm {
  padding: 5px 10px;
  margin-bottom: 5px;
}

.modal-footer .form-group {
  margin: 0 auto;
}

.modal-body .pr-card .card-body {
  padding: 10px;
}

.modal-body .pr-card .card-body p {
  margin-bottom: 5px;
}

.modal-body .pr-card .card-body p:last-child {
  margin-bottom: 0px;
}

.ftrBtn {
  position: fixed;
  bottom: 0;
  margin: 10px auto;
  border-top: 1px solid #e9ecef;
}

.ftrBtn .form-group {
  margin-bottom: 0;
  text-align: center;
}

.ftrBtn .btn,
.ftrBtn input[type="submit"].btn-block {
  width: 460px;
  font-weight: 600;
}

.loaderWpr {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1100;
  background: rgb(0 0 0 / 60%);
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

#loader {
  color: #fff;
  font-size: 5vmax;
  font-weight: 600;
}

.loader {
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 45%;
  width: 90px;
  height: 90px;
  border: 5px solid;
  border-color: #0095da transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  backdrop-filter: blur(100%);
}
.tooltip {
  position: "absolute";
  marginleft: "0%";
  padding: "10% 20% 10% 20%";
}
.apexcharts-tooltip .apexcharts-tooltip-title {
  font-weight: bold; /* Bold header */
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.alert.alert-danger {
  background: #ffe6e7 !important;
  border-color: #ffe6e7 !important;
  color: #e70909;
}

.paramWpr .paramBox {
  text-align: center;
}
.paramWpr .paramBox h3 {
  font-weight: 300;
}

.paramWpr .paramBox.col-lg-2 {
  flex: 0 0 20%;
  max-width: 20%;
}

.paramWpr .paramBox .card-body,
.paramGraph .card-body {
  padding: 0px;
}

.paramWpr .paramBox p {
  margin-bottom: 10px;
  font-weight: 600;
  background: #f5f5fa;
  padding: 5px;
}

.paramGraph {
  text-align: center;
}

.paramGraph p {
  font-weight: 600;
}

.chartWpr {
  min-height: 477px;
}
.chartWpr p {
  background: #f5f5fa;
  padding: 5px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

.gWGzmX {
  font-size: 1rem;
}

.number-input::-webkit-inner-spin-button,
.number-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.number-input:hover::-webkit-inner-spin-button,
.number-input:hover::-webkit-outer-spin-button {
  display: none;
}

.scrollCopyUrl {
  height: 100px;
  overflow-y: scroll;
}

.mb10 {
  margin-bottom: 10px;
}

.alert {
  position: fixed;
  z-index: 1050;
  margin: auto;
  bottom: 2%;
  right: 5%;
  animation: slide-up 0.8s ease-in-out;
}

.alert-success {
  background: #e7fef4;
  border-color: #e7fef4;
  color: #069357;
}

.jINVri {
  min-width: auto !important;
}

.custNameBtn .dropdown-item:active {
  background-color: #e9ecef;
  color: #212529;
}

.date-time-picker-container {
  position: relative;
}

.date-time-picker {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 5px;
  cursor: pointer;
}

.date-time-icon {
  margin-right: 10px;
}

.date-time-picker input {
  border: none;
  outline: none;
  flex: 1;
  font-size: 0.875rem;
}

.map-container {
  width: 100%;
  height: 100%;
}

.places-container {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  width: 300px;
}

.combobox-input {
  width: 100%;
  padding: 0.5rem;
}

.status_icon {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.switch {
  max-width: 17em;
  margin-bottom: 10px;
  width: 160px;
}

.switch label {
  margin-bottom: 0;
}

.switch label:not(.form-check-label):not(.custom-file-label) {
  font-weight: 600;
}

.switch-light > span,
.switch-toggle > span {
  color: #000000;
}

.switch-light span span,
.switch-light label,
.switch-toggle span span,
.switch-toggle label {
  color: #2b2b2b;
}

.switch-toggle a,
.switch-light span span {
  display: none;
}

.switch-light {
  display: block;
  height: 31.5px;
  position: relative;
  overflow: visible;
  padding: 0px;
  margin-left: 0px;
  border: 1px solid #a9b3c1;
  border-radius: 5px;
}

.switch-light * {
  box-sizing: border-box;
}

.switch-light a {
  display: block;
  transition: all 0.3s ease-out 0s;
}

.switch-light label,
.switch-light > span {
  line-height: 30px;
  vertical-align: middle;
}

.switch-light label {
  font-weight: 700;
  margin-bottom: px;
  max-width: 100%;
}

.switch-light input:focus ~ a,
.switch-light input:focus + label {
  outline: 1px dotted rgb(136, 136, 136);
}

.switch-light input {
  position: absolute;
  opacity: 0;
  z-index: 5;
}

.switch-light input:checked ~ a {
  right: 0%;
}

.switch-light > span {
  position: absolute;
  left: -100px;
  width: 100%;
  margin: 0px;
  padding-right: 160px;
  text-align: left;
}

.switch-light > span span {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 5;
  display: block;
  width: 50%;
  margin-left: 100px;
  text-align: center;
  font-size: 0.813rem;
}

.switch-light > span span:last-child {left: 50%;}

.switch-light a {
  position: absolute;
  right: 50%;
  /* right:0; */
  top: 0px;
  z-index: 4;
  display: block;
  width: 50%;
  height: 100%;
  padding: 0px;
}
.switch-light .btn-primary{background-color: #0095da;
  border-color: #0095da;}

.table-container {
  width: 100%;
  height: 100vh;
}

.eCOCms {
  min-height: 250px;
}
.enrgConsBox {
  border-radius: 10px;
  border: 1px solid #d3d5d8;
  text-align: center;
}
.enrgConsBox h3 {
  margin-bottom: 0;
  font-size: 26px;
}
.relayTbl .relayTblscroll {
  height: 254px;
  overflow-y: scroll;
  display: block;
}

.table.phTable {
  margin-bottom: 0;
}
.table.phTable thead th {
  text-align: center;
  font-weight: 700;
}
.table.phTable td {
  text-align: center;
}
.lastValue {
  border-radius: 5px;
  width: 80%;
  font-size: 2rem;
  margin: 0 auto;
  color: #fff;
}
.healthy {
  background: #00b050;
}
.unhealthy {
  background: #bf0000;
}
.ph_gauge p {
  margin: 10px 0;
}
.gaugeWpr {
  margin-top: 70px;
}
.gaugeWpr .speedometer {
  height: 255px !important;
}
.sidebar-collapse .relayOprCnt .relayTblscroll {
  height: 329px;
  overflow-y: scroll;
  display: block;
}
.relayOprCnt .relayTblscroll {
  height: 279px;
  overflow-y: scroll;
  display: block;
}

.relayOprCnt .relayTblscroll tbody {
  width: 100%;
  display: block;
}
.relayOprCnt .relayTblscroll tr {
  width: 100%;
  display: table;
}
.table .relayTblscroll th {
  width: 85%;
}

.table .relayTblscroll th,
.table .relayTblscroll td {
  padding: 5px;
  vertical-align: middle;
}
.oltcCnt h3 {
  margin-bottom: 0;
}

.powerFctBox {
  border-radius: 10px;
  border: 1px solid #d3d5d8;
}
.powerFctBox .card-body {
  padding: 10px;
}
.powerFctBox .card-header {
  padding: 10px;
  border-radius: 10px 10px 0 0;
  font-weight: 500;
}
.powerFctBox p {
  margin-bottom: 0;
}
.avgBox.powerFctBox {
  margin: 0 auto 20px;
  width: 70%;
}
.powerFctBox .inrCardHdr {
  border-radius: 10px 10px 0 0;
}
.powerFctBox h2 {
  font-size: 36px;
  font-weight: 400;
  margin-bottom: 10px;
}
.powerFctBox h2 span {
  font-size: 1rem;
}
.rnBg.card-header {
  background: #ff6873;
  font-weight: 600;
}
.ynBg.card-header {
  background: #fdc163;
  font-weight: 600;
}
.bnBg.card-header {
  background: #7bbfe9;
  font-weight: 600;
}
.pwrFtrPg .speedometer {
  height: 220px !important;
}

.inrCardHdr {
  background-color: #657790;
  text-align: center;
  padding: 10px;
  border-radius: 5px 5px 0 0;
  min-height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.smlCardHdr {
  background-color: #e0e4e9;
  text-align: center;
  padding: 10px;
  border-radius: 10px 10px 0 0;
  min-height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.smlCardHdr h5 {
  color: #000;
  font-size: 0.875rem;
  font-weight: 600;
}
.inrCardBody {
  padding: 10px 20px;
}
.loadBar .inrCardBody {
  min-height: 400px;
}
.sidebar-collapse .loadBar .inrCardBody {
  min-height: 475px;
}
.sidebar-collapse .powerWpr .enrgConsBox .smlCardHdr {
  min-height: 47px;
}
.powerWpr .smlCardHdr {
  min-height: 46px;
}

.enryLosPg .inrCardHdr {
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inrCardHdr h5,
.cardHdr h5 {
  color: #fff;
}
.enryLosPg .inrCardBody {
  padding: 10px;
  text-align: center;
  min-height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cardHdr {
  background-color: #657790;
  text-align: center;
  padding: 10px;
  border-radius: 5px 5px 0 0;
}
.sidebar-collapse .enryLosPg .inrCardBody {
  min-height: 65px;
}
.table th,
.table td {
  padding: 5px;
  color: #000;
  vertical-align: middle;
}
.table th {
  background: #e0e4e9;
}
.table td {
  text-align: center;
}
.table-bordered th,
.table-bordered td,
.table thead th {
  border: 1px solid #d3d5d8;
}
.rBg {
  background: #ff6873 !important;
}
.yBg {
  background: #fdc163 !important;
}
.bBg {
  background: #7bbfe9 !important;
}
.valueFont {
  font-size: 0.875rem !important;
  font-weight: 500;
}
.tdtimeBg {
  font-weight: 500;
  background: #f5f5fa !important;
}

.sidebar-collapse .enryPg .cards .inrCardHdr {
  min-height: 35px;
}
.sidebar-collapse .enryPg .cards .inrCardBody {
  min-height: 55px;
}
.enryPg .cards .inrCardHdr {
  min-height: 60px;
}
.enryPg .cards .inrCardBody {
  min-height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ph_gauge .inrCardBody {
  min-height: 150px;
}
.loadProfile .inrCardBody {
  min-height: 395px;
}
.sidebar-collapse .loadProfile .inrCardBody {
  min-height: 475px;
}
.apexcharts-menu-item {
  font-size: 10px;
}
.apexcharts-menu-item {
  padding: 4px !important;
  font-weight: 500;
}
.separator {
  height: 349px;
  border-right: 1px solid #eee;
}
.inrCardBody h3 {
  line-break: anywhere;
  font-size: 26px;
}
.pfWidth {
  width: 47px;
}
.sidebar-collapse .pfWidth {
  width: 53px;
}
.alert-dismissible .btn-close {
  padding: 1rem;
}
.toolWpr p {
  margin-bottom: 0;
}
.dateWidth {
  min-width: 100px;
}

.sldHeadWpr {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px;
}
.sld_legend ul {
  float: right;
  padding: 0.75rem 1rem;
}
.sld_legend li {
  display: inline-block;
  padding: 5px;
}
.sld_legend span {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-top: 4px;
  margin-right: 2px;
  display: inline-block;
}
.sld_legend .green {
  background: #92d050;
}
.sld_legend .red {
  background: #ff0000;
}
.sldSwitchWpr.switch {
  padding: 0.75rem 1rem;
  margin-bottom: 0;
  width: 94px;
}
.sldSwitchWpr .switch-light {
  width: 60px;
  height: 27px;
  border-radius: 34px;
  background-color: #2196f3;
  border: 1px solid #2196f3;
}
.sldSwitchWpr a {
  height: 24px;
  width: 24px;
  background-color: white;
  border-radius: 50%;
  right: 56%;
}

.dataTables_filter .sldSwitchWpr input[type="checkbox"] {
  width: 50px;
  height: 25px;
  padding: 0.25rem 0.5rem;
  border-radius: 30px;
}

.noDataTbl th {
  background: none;
}
.noDataTbl.table thead th {
  border: none;
  font-size: 0.875rem;
}
.noDataTbl p {
  background: none;
}
.breadcrumbHdr .form-select {
  width: auto;
}
.busbarCard.card {
  margin-bottom: 0;
}
.summTbl table {
  margin-bottom: 0;
}

.js-plotly-plot .plotly .main-svg {
  width: 100%;
}
.map :focus-visible {
  outline: none !important;
}
.faultStatus {
  height: 10px;
  width: 10px;
  border-radius: 100%;
  display: inline-block;
  margin-right: 5px;
}
.faultRed {
  background: #e75834;
}
.faultGreen {
  background: #80d080;
}
.infoCard.pr-card .card-body {
  padding: 15px 20px 0px 20px;
}

.alerttblCard .card-header {
  background-color: #657790;
  min-height: 35px;
  padding: 10px 20px;
}
.alerttblCard .card-header h5 {
  text-align: left;
  color: #fff;
  float: left;
}
.alerttblCard .card-header a {
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
a.info-box {
  color: #000;
}
.alerttblCard .card-header a:hover {
  color: #fff;
}
.info-box .info-box-text {
  font-size: 15px !important;
  font-weight: 600 !important;
}
.gcBbvz {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  line-height: normal;
  padding-left: 16px;
  padding-right: 16px;
  word-break: break-word;
  font-weight: 500;
  font-size: 0.875rem;
}
.loadProfile .inrCardBody {
  min-height: 425px;
}
.loadBar .inrCardBody {
  min-height: 425px;
}

.no-data {
  text-align: center; /* Center the text */
  font-weight: 500; /* Increase font weight */
}
.unbalaWpr .no-data {
  margin-top: 130px;
}
.no-datacard {
  text-align: center; /* Center the text */
  font-weight: 500; /* Increase font weight */
  margin-top: 133px;
}

.sidebar-mini.sidebar-collapse .main-sidebar,
.sidebar-mini.sidebar-collapse .main-sidebar::before {
  margin-left: 0;
  width: 4px !important;
}
.unbalaMidl {
  height: 400px;
}
.unbalaWpr {
  height: 400px;
}

/* .voltageCard .inrCardBody, .currentCard .inrCardBody{height: 420px;} */
.tmpTbl .inrCardBody,
.pFactor .inrCardBody {
  padding: 20px;
}

.fleetWpr .switch{width: 170px;margin-right: 1%;}
.scroll-x {margin-bottom: 2px;
  max-width: 100%;
  overflow-x: auto;
  overscroll-behavior-x: contain;
  scrollbar-color: var(--color-fg) var(--color-bg);
}

.items-x {
  grid-area: content;
  display: flex;
  gap: var(--gap);

  &::after {
    content: "";
    padding-inline-end: max(
      var(--gutter),
      (100vw - var(--size)) / 2 - var(--gutter)
    );
  }

  > * {
    place-content: center;
    flex-shrink: 0;
    scroll-snap-align: center;
    font-weight: 600;
    color: white;
    background-color: var(--color-fg);
    border-radius: 0.5rem;
  }
}

.items-x li {
  display: inline-block;
  margin-right: 0.7rem;
}
/* #carouselExample{overflow-y: scroll;} */
/* .fleetWpr.mdlContent.card-body{overflow-y: hidden;} */
.fleetWpr.mdlContent.card-body {
  padding: 15px 6px 12px 12px;
}
.txViewWpr .fleetWpr.mdlContent.card-body {
  padding: 12px;
}
.fullScreenWpr img,
.fullScreenExit img {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 10px rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  padding: 5px;
}
.topBtnCard {
  margin: 20px 20px 0 20px;
}
.topBtn.inrCardBody {
  padding: 10px;
}
.topBtn.inrCardBody .btn {
  border: 1px solid #a9b3c1;
  padding: 0.313rem 0.625rem;
  margin-bottom: 5px;
}
.topBtn.inrCardBody .btn.active {
  border: transparent;
  background-color: #0095da;
  color: #fff;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
}
.topBtn.inrCardBody .btn:hover {
  background-color: #0095da;
  color: #fff;
}
.topBtn.inrCardBody a {
  font-weight: 700;
}
/* .topBtn.inrCardBody .btn.btn-primary{border: transparent;} */
/* .fleetWpr .info-box{min-height:auto;} */
.fleetWpr .table td {
  text-align: left;
}
/* .fleetWpr .info-box .switch-light{height: 36px;} */
/* .fleetWpr .info-box .info-box-icon{width: 26px;} */
.topBtnCard .form-group {
  margin-bottom: 0;
}
.topBtnCard .switch {
  margin-right: 4%;
}
.fleetWpr .switch,
.fleetWpr .carousel-indicators,
.selectassetWpr.form-group {
  margin-bottom: 0;
}
.fleetWpr .inrCardHdr .switch {
  position: absolute;
  right: 0;
}
.fleetWpr .inrCardHdr .switch-light {
  background: #e0e4e9;
}
.fleetWpr .carousel-indicators [data-bs-target] {
  height: 15px;
  width: 15px;
  background: #657790;
  border-top: 0;
  border-bottom: 0;
  border-radius: 50%;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 3px 3px rgba(0, 0, 0, 0.2);
}
/* .fleetWpr .carousel-inner{height: 76.5vh;} */
.fleetWpr.mdlContent.card-body {
  overflow-y: inherit;
}
.fleetWpr .innerWpr {
  height: 78vh;
}
.txViewWpr .fleetWpr .carousel-inner {
  height: 81.5vh;
}
/* .fleetWpr .form-group .info-box{width: auto; display: inline-block; margin-bottom: 0; margin-left: 5px;min-height: 26px !important;padding: .4rem;} */
.fleetWpr .pr-card .breadcrumbHdr.card-header{padding: 10px; text-align: center; border-radius: 5px 5px 0 0; margin-bottom: 20px;}
.fleetWpr .eleParaTbl .table{table-layout: fixed;}
.fleetWpr .eleParaTbl.inrCardBody{height: 71vh;}
.fleetWpr .table.eleParaTbl th{font-size: 0.875rem; text-align: center; padding: 1rem 1rem; height: 55px; vertical-align: middle; border: 1px solid #657790;}
.fleetWpr .table.eleParaTbl td a{padding: 1.5rem 1rem;}
.fleetWpr .table.eleParaTbl td{font-size: 0.938rem;text-align: center; border: 1px solid #657790;}
.bg-yellow{background: #FDC163;}
.fleetWpr .bg-redPin{background:#FF6873!important}
.fleetWpr .table td a{color: #000; display: block;}
.fleetWpr .table td{font-size: 0.75rem;}
.fleetWpr .eleParaTbl th{font-size: 0.75rem;}
.td_width{width: 25%;}
.fleetWpr .table-hover tbody tr:hover{background-color: #0095da!important;}
.fullScreenWpr, .fullScreenExit{position: absolute; right: 0; bottom: 0; z-index: 15;}
.selectassetWpr{text-align: center;}
.selectassetWpr .form-control{display: inline-block;height: 32px;font-size: 1rem;text-align: center; background-color: #e0e4e9;}
.chartGrp .pr-card .card-body{padding: 0 20px;height: 210px;}
.chartGrp .inrCardHdr{min-height: 58px;}
.tblGrp .inrCardHdr{justify-content:left;}
.tblGrp .table{margin-bottom: 0;}

.swch-radio-picker {
  margin: 4px 13px 0 0;
}
.inrCardHdr .swch-radio-picker {
  margin: 0px 13px 0 0;
}
.swch-rad {
  opacity: 0;
  position: absolute;
}
.swch-hold {
  display: inline;
}
.swch-hold label {
  background: #fff;
  border: 1px solid #a9b3c1;
  padding: 7.5px 6px;
  border-radius: 0px;
  color: #000;
  margin: -3px;
  display: inline-grid;
  min-width: 100px;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
}
.swch-rad:checked + label,
.fleetWpr .inrCardHdr .swch-rad:checked + label {
  background: #007bff;
  color: #fff;
}
.fleetWpr .inrCardHdr .swch-radio-picker {
  position: absolute;
  right: 0;
}
.fleetWpr .inrCardHdr .swch-hold label {
  background: #e0e4e9;
}
.latestArtTbl .inrCardBody {
  height: 231px;
  overflow-y: scroll;
}
.latestArtTbl th:first-child {
  width: 13%;
}
.latestArtTbl :nth-child(5) {
  width: 8%;
}
.sidebar-mini.sidebar-collapse .fullscreen .content-wrapper {
  margin-left: 0rem !important;
}
.txViewWpr .tblGrp .relayListTbl .inrCardBody {
  overflow-y: scroll;
  height: 445px;
}
.txViewWpr .tblGrp .relayListTbl .inrCardBody p {
  margin-top: 40%;
}
.txViewWpr .alertTbl .inrCardBody {
  height: 200px;
  overflow-y: scroll;
}
.txViewWpr .eleParaTbl .inrCardBody {
  height: 190px;
  overflow-y: scroll;
}
.txViewWpr .eleParaTbl .inrCardBody p {
  margin-top: 7%;
}
.chartGrp .pr-card .card-body p {
  margin-top: 25%;
}
.chartGrp .col-md-6.col-lg-6 .pr-card .card-body p {
  margin-top: 12%;
}
.txViewWpr .alertTbl th:first-child {
  width: 16%;
}
.txViewWpr .alertTbl .inrCardBody p {
  margin-top: 8%;
}
select.minimal {
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(0.9em + 0px),
    calc(100% - 15px) calc(0.9em + 0px), calc(100% - 2.5em) 0.2em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

/* select.minimal:focus {
  background-image:
    linear-gradient(45deg, green 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, green 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 15px) 1em,
    calc(100% - 20px) 1em,
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
  border-color: green;
  outline: 0;
} */

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
.fullscreen .fleetWpr .innerWpr {
  height: 80vh;
}
.fullscreen .h5,
.fullscreen h5 {
  font-size: 1.125rem;
}
.fullscreen .inrCardHdr h5,
.fullscreen .cardHdr h5 {
  font-size: 1.063rem;
}
.fullscreen .table th,
.fullscreen .table td {
  padding: 11px 5px;
}
.fullscreen .fleetWpr .table td {
  font-size: 0.875rem;
}
.fullscreen .table thead th {
  font-size: 0.938rem;
}
.fullscreen .fleetWpr .eleParaTbl.inrCardBody {
  height: 72vh;
}
.fullscreen .fleetWpr .table.eleParaTbl td a {
  padding: 2rem 1rem;
}
.fullscreen .txViewWpr .fleetWpr .carousel-inner {
  height: 82.5vh;
}
.fleetWpr .apexcharts-tooltip-y-group {
  padding: 2px 0 5px;
}
.fleetWpr .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  font-size: 0.688rem !important;
  padding: 2px;
}
.fleetWpr .apexcharts-tooltip {
  font-size: 0.625rem !important;
}
.apexcharts-tooltip-series-group.apexcharts-active,
.apexcharts-tooltip-series-group:last-child {
  padding-bottom: 1px;
}
.fullscreen .txViewWpr .alertTbl .inrCardBody,
.fullscreen .txViewWpr .eleParaTbl .inrCardBody {
  height: 252px;
}
.fullscreen .txViewWpr .fleetWpr .table td {
  font-size: 0.813rem;
}
.fullscreen .txViewWpr .table th,
.fullscreen .txViewWpr .table td {
  padding: 9px 5px;
}
.fullscreen .txViewWpr .tblGrp .relayListTbl .inrCardBody {
  height: 560px;
}
.fullscreen .latestArtTbl .inrCardBody {
  height: 280px;
}

.historicalpg .form-inline {
  margin-top: 20px;
}
.historicalpg a {
  color: #000;
}
.requestIdModal .modal-header, .inactiveModal .modal-header, .disableModal .modal-header{ display: block; background: #D9D9D9; border-top-left-radius:20px; border-top-right-radius:20px;}
.hstForm .btn{font-size: 0.875rem;}

.switchSmall {
  display: inline-block;
}
.switchSmall input {
  display: none;
}
.switchSmall small {
  display: inline-block;
  width: 60px;
  height: 27px;
  border: 1px solid #c9c9c9;
  border-radius: 30px;
  position: relative;
  cursor: pointer;
}
.switchSmall small:before {
  content: "";
  position: absolute;
  width: 21px;
  height: 21px;
  background: #c9c9c9;
  border-radius: 50%;
  top: 2px;
  left: 3px;
  transition: .3s;
  box-shadow: -3px 0 3px rgba(0,0,0,0.1);
}
.switchSmall input:checked ~ small {
  /* background: #4fc5c5; */
  transition: .3s;
}
.switchSmall input:checked ~ small:before {
  transform: translate(30px, 0px);
  transition: .3s;
  background: #0095da;
}
.historicalpg .datalabel label{margin-bottom: 0!important;}
.statusTgl .switch{margin-bottom: 0;}
/* .statusTgl.switch-light input:checked ~ a {right: 0%;} */
.statusTgl input:checked ~ small:before{background: #ccc;}
.statusTgl .switch-light input:checked ~ a {
  right: 50%;
}
.statusTgl .form-control{height: calc(1.713rem + 2px);}
.statusTgl .switch-light a{z-index: 0;}
.assetWidth{width: 70px; display: block; text-align: center;}
.assetalloForm .css-13cymwt-control, .assetalloForm .css-b62m3t-container, .assetalloForm .css-1dyz3mf, .assetalloForm .css-hlgwow{min-height: 150px; align-items: self-start;}
.assetalloForm .css-13cymwt-control{ overflow-y: scroll;}
.assetalloForm .css-1dyz3mf{display: ruby;}
.pt10{padding-top: 10px;}
.eGxbrW, .doSrUc{font-weight: bold;}
.historicalpg .assetAloTbl .form-inline, .historicalpg .userTbl .form-inline, .historicalpg .assetTbl .form-inline{margin-top: 0px;}
.historicalpg .assetAloTbl a, .historicalpg .userTbl a, .historicalpg .assetTbl a{color: #0095da;}
.csv{background-image: url(../images/csv.png);
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  height: 30px; width: 19px; cursor: pointer;position: absolute;
  top: 50px;
  right: 20px;
  z-index: 99;}

.static-sidebar{width: 90px;}
.historicalpg  .breadcrumb p{position: absolute; right: 20px; font-weight: 600; margin-bottom: 0;}


@media (max-width: 1600px) {
  body {
    font-size: 12px;
  }
  .sidebarLeft {
    z-index: 1040;
    position: aboulte;
    padding-top: 10px;
    background: #e0e4e9;
    float: left;
    width: auto;
    height: 100vh;
    /* overflow-y: scroll; */
  }
  /* .js-plotly-plot .plotly .user-select-none {
    margin-top: -24px;
    } */
    /* .js-plotly-plot{
      height: 330px !important;
      margin-top: 26px;
    } */
    .txViewWpr .chartGrp .pr-card .card-body {
      height: 160px;
  }
  /* .js-plotly-plot {
    height: 280px !important  ;
    margin-top: 26px;
  } */
  .txViewWpr .chartGrp .pr-card .card-body {
    height: 160px;
  }
  .custom-plot-height {
    height: 150px !important  ;
  }
  .kzZBXj {
    overflow: hidden;
    white-space: unset !important;
    text-overflow: ellipsis;
    font-size: 0.813rem;
  }
  .unbalaWpr {
    height: 300px;
  }
  .unbalaWpr .no-data {
    margin-top: 100px;
  }
  .separator {
    height: 245px;
    border-right: 1px solid #eee;
  }
  .custom-cell {
    font-size: 12px;
    font-weight: 550;
  }
  .hSQKHD{
    font-size: 12px !important;;
      font-weight: 500 !important;  /* Add !important */
      color: rgba(0, 0, 0, 0.87);min-height: 40px!important;
  }
  .dropdown-item {
    font-size: 0.688rem;
    font-weight: 500;
  }
  .noDataTbl.table thead th {
    border: none;
    font-size: 0.813rem;
  }
  .paramWpr .paramBox .card-body,
  .paramGraph .card-body {
    padding: 0px;
    height: 75px;
  }
  .info-box .info-box-text {
    font-size: 0.813rem !important;
    font-weight: 600 !important;
  }
  .brand-link {
    padding: 10px 0.5rem;
  }
  .pr-card .breadcrumbHdr.card-header .breadcrumb {
    padding: 7px 10px;
  }
  .breadcrumb a {
    font-size: 0.813rem;
    line-height: 0.875rem;
  }
  .breadcrumb-item,
  .breadcrumb-item + .breadcrumb-item::before {
    line-height: 25px;
  }
  .pr-card .breadcrumbHdr.card-header .breadcrumb {
    background: transparent;
    margin-bottom: 0;
    font-size: 0.813rem;
  }
  .inrCardHdr h5,
  .cardHdr h5 {
    font-size: 15px;
  }
  .login-section .input-group > .input-group-prepend > .input-group-text {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    font-size: 0.875rem;
  }
  .apexcharts-xaxis-label {
    font-family: "Montserrat", sans-serif !important;
    font-size: 0.688rem !important;
    font-weight: 500 !important;
  }
  .apexcharts-legend-text {
    font-family: "Montserrat", sans-serif !important;
    font-size: 0.688rem !important;
    font-weight: 500 !important;
  }
  .apexcharts-yaxis-label {
    font-family: "Montserrat", sans-serif !important;
    font-size: 0.688rem !important;
    font-weight: 500 !important;
  }
  .apexcharts-yaxis-title-text {
    font-family: "Montserrat", sans-serif !important;
    font-size: 0.688rem !important;
    font-weight: 500 !important;
  }
  .apexcharts-xaxis-title-text {
    font-family: "Montserrat", sans-serif !important;
    font-size: 0.688rem !important;
    font-weight: 500 !important;
  }
  .cable {
    background-size: 23px;
    height: 23px;
  }
  .transformer {
    background-size: 18px;
    height: 20px;
  }
  .aqiq {
    background-size: 26px;
    height: 20px;
  }
  .other,
  .hvac,
  .motor {
    background-size: 20px;
    height: 20px;
  }
  .ems,
  .selco {
    background-size: 19px;
    height: 19px;
  }
  .ups {
    height: 20px;
  }
  .nav-link.active {
    font-size: 12px;
    line-height: 18px;
  }
  .nav-link {
    font-size: 12px;
  }
  .paramWpr .paramBox h3 {
    font-weight: 400;
  }
  label:not(.form-check-label):not(.custom-file-label),
  .form-control {
    font-size: 0.75rem;
    line-height: 12px;
  }

  #editDevice #modal_map iframe {
    height: 310px;
  }

  .mdlContent.card-body {
    padding: 10px;
  }
  .mdlContent.card-body {
    margin: 10px 0;
  }
  .btn {
    padding: 5px 10px;
  }
  .pr-card.tableCard .card-header {
    padding: 20px 20px 10px 20px;
  }
  .form-control.uploadWpr {
    line-height: 24px;
  }
  .ftrBtn .btn,
  .ftrBtn input[type="submit"].btn-block {
    width: 441px;
    font-weight: 600;
  }
  .chartWpr {
    min-height: 440px;
  }
  .sidebar-collapse .relayTbl .relayTblscroll {
    height: 329px;
  }
  .sidebar-collapse .relayOprCnt .relayTblscroll {
    height: 188px;
  }

  .relayOprCnt .relayTblscroll {
    height: 205px;
  }
  .enryLosPg .inrCardHdr,
  .sidebar-collapse .enryLosPg .inrCardHdr {
    min-height: 50px;
  }
  .sidebar-collapse .enryLosPg .inrCardBody {
    min-height: 70px;
  }
  .inrCardHdr h5,
  .cardHdr h5 {
    font-size: 12px;
  }
  .h3,
  h3 {
    font-size: 0.875rem;
  }
  .table td {
    font-size: 12px;
    font-weight: 500;
  }
  .gcBbvz {
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    line-height: normal;
    padding-left: 16px;
    padding-right: 16px;
    word-break: break-word;
    font-weight: 500;
    font-size: 12px;
  }
  .valueFont {
    font-size: 12px !important;
    font-weight: 500;
  }
  .smlCardHdr h5 {
    color: #000;
    font-size: 0.813rem;
    font-weight: 600;
  }
  .inrCardBody h3 {
    line-break: anywhere;
    font-size: 20px;
  }
  .h5,
  h5 {
    font-size: 0.813rem;
    margin: 0;
    font-weight: 600;
    color: #000;
  }
  .ytitle {
    font-size: 0.688rem;
    font-weight: 500;
  }
  .smlCardHdr {
    min-height: 51px;
  }
  .powerWpr .enrgConsBox .smlCardHdr {
    flex: 1 1 100%; /* Ensures the card stretches */
    display: flex; /* This makes the card content flexible */
    flex-direction: column; /* Ensures label and number are stacked */
    justify-content: space-between; /* Makes the content spread evenly */
    min-height: 80px; /* Set a minimum height if needed */
  }
  .enrgConsBox .inrCardBody {
    flex: 1 1 100%; /* Ensures the card stretches */
    display: flex; /* This makes the card content flexible */
    flex-direction: column; /* Ensures label and number are stacked */
    justify-content: space-between; /* Makes the content spread evenly */
    min-height: 70px; /* Set a minimum height if needed */
  }
  .tblScroll {
    height: auto;
    overflow-y: scroll;
  }
  .table-height {
    height: 443px;
  }
  .relayTbl .relayTblscroll {
    height: 330px;
  }

  .col-md-4 .powerFctBox p {
    min-height: 36px;
  }
  .sidebar-collapse .loadBar .inrCardBody {
    min-height: 325px;
  }
  .loadBar .inrCardBody {
    min-height: 297px;
  }
  .sidebar-collapse .powerWpr .enrgConsBox .smlCardHdr {
    flex: 1 1 100%; /* Ensures the card stretches */
    display: flex; /* This makes the card content flexible */
    flex-direction: column; /* Ensures label and number are stacked */
    justify-content: space-between; /* Makes the content spread evenly */
    min-height: 80px; /* Set a minimum height if needed */
  }
  .react-datepicker {
    font-size: 0.688rem;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    line-height: 16px;
  }
  .tmpTbl.card {
    margin-bottom: 45px;
  }
  .tmpTbl .inrCardBody {
    margin: 0;
    height: 245px;
  }
  .tmpTbl .inrCardBody .table {
    height: 200px;
  }
  .pfTbl {
    margin: 5px 0;
  }
  .pfWidth {
    width: 35px;
  }
  .sidebar-collapse .pfWidth {
    width: 37px;
  }
  .sldSwitchWpr.switch {
    width: 70px;
    padding: 11px 10px;
  }
  .sldSwitchWpr .switch-light {
    width: 40px;
    height: 15px;
  }
  .sldSwitchWpr a {
    height: 13px;
    width: 13px;
    right: 61%;
  }
  .breadcrumbHdr .form-select {
    line-height: 1;
  }
  .breadcrumbHdr .dataTables_filter input[type="checkbox"] {
    height: 16px;
  }
  .table .relayTblscroll th {
    width: 75%;
  }
  .loadProfile .inrCardBody {
    min-height: 340px;
  }
  .ph_gauge .inrCardBody {
    min-height: 124px;
  }
  .sidebar-collapse .loadProfile .inrCardBody {
    min-height: 325px;
  }
  .no-data {
    text-align: center; /* Center the text */
    font-weight: 500; /* Increase font weight */
  }
  .no-datacard {
    text-align: center; /* Center the text */
    font-weight: 500; /* Increase font weight */
    margin-top: 37%;
  }
  .sidebar-collapse .enryPg .cards .inrCardHdr {
    min-height: 51px;
  }
  /* .fleetWpr .table.eleParaTbl th, .fleetWpr .table.eleParaTbl td a {
  padding: 1.6rem 1rem;
} */
/* .fleetWpr .carousel-inner {height: 74vh;} */
.txViewWpr .fleetWpr .carousel-inner {height: 80vh;}
.fleetWpr .innerWpr {height: 75vh;}
.topBtn.inrCardBody .btn {padding: 0.25rem 0.625rem; font-size: 0.75rem;}
.topBtnCard .switch, .fleetWpr .switch{margin-right: 2%;}
.topBtnCard .switch-light > span span, .fleetWpr .switch-light > span span{font-size: 0.75rem;}
.topBtnCard .switch-light, .fleetWpr .switch-light{height: 27.5px;}
.topBtnCard .switch-light > span, .fleetWpr .switch-light > span{line-height: 27px;}
.fleetWpr.mdlContent.card-body{margin: 0;}
.fleetWpr .inrCardHdr, .fleetWpr .pr-card .breadcrumbHdr.card-header {padding: 7px; min-height: 30px;}
.latestArtTbl .inrCardBody {height: 195px;}
.latestArtTbl .inrCardBody .table{margin-bottom: 0;}
.fleetWpr .table thead th {line-height: 12px;}
.fleetWpr .table.eleParaTbl td a{padding: 1.3rem 1rem;}
.fleetWpr .table.eleParaTbl th {font-size: 0.813rem;height: 55px;}
.fleetWpr .table.eleParaTbl td{font-size: 0.875rem;}
.fleetWpr .eleParaTbl.inrCardBody {height: 69vh;}
.fleetWpr .pr-card .breadcrumbHdr.card-header{margin-bottom: 10px;}
.txViewWpr .alertTbl .inrCardBody {height: 155px;}
.txViewWpr .tblGrp .relayListTbl .inrCardBody{height: 360px;}
.txViewWpr .eleParaTbl .inrCardBody{height: 160px;}
.txViewWpr .eleParaTbl .inrCardBody p {margin-top: 5%;}
.txViewWpr .fleetWpr .table td, .txViewWpr .fleetWpr .eleParaTbl th, .txViewWpr .tblGrp .relayListTbl th{font-size: 0.688rem;padding: 3px;}
/* .txViewWpr .tblGrp .relayListTbl .inrCardBody p {margin-top: 50%;} */
.selectassetWpr .form-control {height: 27px;font-size: 0.938rem;}
select.minimal {background-position: calc(100% - 20px) calc(0.7em + 0px), calc(100% - 15px) calc(.7em + 0px), calc(100% - 2.5em) 0.2em;}
.chartGrp .pr-card .card-body {height: 170px;}
.fullscreen .fleetWpr .innerWpr {height: 77vh;}
.fullscreen .fleetWpr .table.eleParaTbl td a {padding: 1.4rem 1rem;}
.fullscreen .fleetWpr .eleParaTbl.inrCardBody {height: 69.5vh;}
.fullscreen .txViewWpr .alertTbl .inrCardBody, .fullscreen .txViewWpr .eleParaTbl .inrCardBody {height: 180px;}
.fullscreen .txViewWpr .tblGrp .relayListTbl .inrCardBody {height: 430px;}
.fullscreen .chartGrp .pr-card .card-body p {margin-top: 20%;}
.fullscreen .chartGrp .col-md-6.col-lg-6 .pr-card .card-body p {margin-top: 10%;}
.fullscreen .txViewWpr .fleetWpr .inrCardHdr, .fullscreen .txViewWpr .fleetWpr .pr-card .breadcrumbHdr.card-header {min-height: 51px;}
.fullscreen .txViewWpr .inrCardHdr h5, .fullscreen .txViewWpr .cardHdr h5 {font-size: 1rem;}
.txViewWpr .eleParaTbl .inrCardBody p {margin-top: 9%;}
.txViewWpr .alertTbl th:first-child{width: 20%;}
.fullscreen .table th, .fullscreen .table td {padding: 9px 5px;}
.fullscreen .latestArtTbl .inrCardBody {height: 250px;}
.td_width{width: 29%;}
.static-sidebar{width: 85px;}
}

@media (max-width: 1536px) {
  body {
    font-size: 12px;
  }

  .sidebarLeft {
    z-index: 1040;
    position: aboulte;
    padding-top: 10px;
    background: #e0e4e9;
    float: left;
    width: auto;
    height: 100vh;
    /* overflow-y: scroll; */
  }
  /* .js-plotly-plot .plotly .user-select-none {
    margin-top: -34px;
  } */
  /* .js-plotly-plot {
    height: 320px !important  ;
  } */
  .kzZBXj {
    overflow: hidden;
    white-space: unset !important;
    text-overflow: ellipsis;
    font-size: 0.813rem;
  }
  .custom-cell {
    font-size: 12px;
    font-weight: 550;
  }
  .hSQKHD {
    font-size: 12px !important;
    font-weight: 500 !important; /* Add !important */
    color: rgba(0, 0, 0, 0.87);
  }
  .dropdown-item {
    font-size: 0.688rem;
    font-weight: 500;
  }
  .noDataTbl.table thead th {
    border: none;
    font-size: 0.813rem;
  }
  .info-box .info-box-text {
    font-size: 0.813rem !important;
    font-weight: 600 !important;
  }
  .brand-link {
    padding: 10px 0.5rem;
  }
  .pr-card .breadcrumbHdr.card-header .breadcrumb {
    padding: 7px 10px;
  }
  .breadcrumb a {
    font-size: 0.813rem;
    line-height: 14px;
  }
  .breadcrumb-item,
  .breadcrumb-item + .breadcrumb-item::before {
    line-height: 25px;
  }
  .pr-card .breadcrumbHdr.card-header .breadcrumb {
    background: transparent;
    margin-bottom: 0;
    font-size: 0.813rem;
  }
  .inrCardHdr h5,
  .cardHdr h5 {
    font-size: 15px;
  }
  .login-section .input-group > .input-group-prepend > .input-group-text {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    font-size: 0.875rem;
  }
  .apexcharts-xaxis-label {
    font-family: "Montserrat", sans-serif !important;
    font-size: 0.688rem !important;
    font-weight: 500 !important;
  }
  .apexcharts-legend-text {
    font-family: "Montserrat", sans-serif !important;
    font-size: 0.688rem !important;
    font-weight: 500 !important;
  }
  .apexcharts-yaxis-label {
    font-family: "Montserrat", sans-serif !important;
    font-size: 0.688rem !important;
    font-weight: 500 !important;
  }
  .apexcharts-yaxis-title-text {
    font-family: "Montserrat", sans-serif !important;
    font-size: 0.688rem !important;
    font-weight: 500 !important;
  }
  .apexcharts-xaxis-title-text {
    font-family: "Montserrat", sans-serif !important;
    font-size: 0.688rem !important;
    font-weight: 500 !important;
  }

  .transformer {
    background-size: 18px;
    height: 20px;
  }
  .aqiq {
    background-size: 23px;
    height: 23px;
  }
  .other,
  .hvac,
  .motor {
    background-size: 20px;
    height: 20px;
  }
  .cable, .selco {
    background-size: 19px;
    height: 19px;
}
.ems {
  background-size: 13px;
  height: 13px;
}
  .ups {
    height: 20px;
  }
  .nav-link.active {
    font-size: 12px;
    line-height: 12px;
  }
  .nav-link {
    font-size: 12px;
  }
  label:not(.form-check-label):not(.custom-file-label),
  .form-control {
    font-size: 12px;
    line-height: 12px;
  }

  #editDevice #modal_map iframe {
    height: 310px;
  }

  .mdlContent.card-body {
    padding: 10px;
  }
  .mdlContent.card-body {
    margin: 10px 0;
  }
  .btn {
    padding: 5px 10px;
  }
  .pr-card.tableCard .card-header {
    padding: 20px 20px 10px 20px;
  }
  .form-control.uploadWpr {
    line-height: 24px;
  }
  .ftrBtn .btn,
  .ftrBtn input[type="submit"].btn-block {
    width: 375px;
    font-weight: 600;
  }
  .chartWpr {
    min-height: 440px;
  }
  .sidebar-collapse .relayTbl .relayTblscroll {
    height: 329px;
  }
  .sidebar-collapse .relayOprCnt .relayTblscroll {
    height: 188px;
  }

  .relayOprCnt .relayTblscroll {
    height: 162px;
  }
  .enryLosPg .inrCardHdr,
  .sidebar-collapse .enryLosPg .inrCardHdr {
    min-height: 50px;
  }
  .sidebar-collapse .enryLosPg .inrCardBody {
    min-height: 70px;
  }
  .inrCardHdr h5,
  .cardHdr h5 {
    font-size: 12px;
  }
  .h3,
  h3 {
    font-size: 0.875rem;
  }
  .table td {
    font-size: 12px;
    font-weight: 500;
  }
  .gcBbvz {
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    line-height: normal;
    padding-left: 16px;
    padding-right: 16px;
    word-break: break-word;
    font-weight: 500;
    font-size: 12px;
  }
  .valueFont {
    font-size: 12px !important;
    font-weight: 500;
  }
  .smlCardHdr h5 {
    color: #000;
    font-size: 0.813rem;
    font-weight: 600;
  }
  .inrCardBody h3 {
    line-break: anywhere;
    font-size: 20px;
  }
  .h5,
  h5 {
    font-size: 0.813rem;
    margin: 0;
    font-weight: 600;
    color: #000;
  }
  .ytitle {
    font-size: 0.688rem;
    font-weight: 500;
  }
  .smlCardHdr {
    min-height: 51px;
  }
  .powerWpr .enrgConsBox .smlCardHdr {
    flex: 1 1 100%; /* Ensures the card stretches */
    display: flex; /* This makes the card content flexible */
    flex-direction: column; /* Ensures label and number are stacked */
    justify-content: space-between; /* Makes the content spread evenly */
    min-height: 80px; /* Set a minimum height if needed */
  }
  .enrgConsBox .inrCardBody {
    flex: 1 1 100%; /* Ensures the card stretches */
    display: flex; /* This makes the card content flexible */
    flex-direction: column; /* Ensures label and number are stacked */
    justify-content: space-between; /* Makes the content spread evenly */
    min-height: 70px; /* Set a minimum height if needed */
  }
  .tblScroll {
    height: auto;
    overflow-y: scroll;
  }
  .relayTbl .relayTblscroll {
    height: 330px;
  }

  .col-md-4 .powerFctBox p {
    min-height: 36px;
  }
  .sidebar-collapse .loadBar .inrCardBody {
    min-height: 325px;
  }
  .loadBar .inrCardBody {
    min-height: 297px;
  }
  .sidebar-collapse .powerWpr .enrgConsBox .smlCardHdr {
    flex: 1 1 100%; /* Ensures the card stretches */
    display: flex; /* This makes the card content flexible */
    flex-direction: column; /* Ensures label and number are stacked */
    justify-content: space-between; /* Makes the content spread evenly */
    min-height: 80px; /* Set a minimum height if needed */
  }
  .react-datepicker {
    font-size: 0.688rem;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    line-height: 16px;
  }
  .tmpTbl.card {
    margin-bottom: 25px;
  }
  .tmpTbl .inrCardBody {
    margin: 0;
  }
  .pfTbl {
    margin: 5px 0;
  }
  .pfWidth {
    width: 35px;
  }
  .sidebar-collapse .pfWidth {
    width: 37px;
  }
  .sldSwitchWpr.switch {
    width: 70px;
  }
  .sldSwitchWpr .switch-light {
    width: 40px;
    height: 15px;
  }
  .sldSwitchWpr a {
    height: 13px;
    width: 13px;
    right: 61%;
  }
  .breadcrumbHdr .form-select {
    line-height: 1;
  }
  .breadcrumbHdr .dataTables_filter input[type="checkbox"] {
    height: 16px;
  }
  .table .relayTblscroll th {
    width: 75%;
  }
  .loadProfile .inrCardBody {
    min-height: 297px;
}
.sidebar-collapse .loadProfile .inrCardBody {
  min-height: 325px;
}
.no-data {
  text-align: center; /* Center the text */
  font-weight: 500;  /* Increase font weight */
}
.no-datacard {
  text-align: center; /* Center the text */
  font-weight: 500;  /* Increase font weight */
  margin-top: 133px;
}
.sidebar-collapse .enryPg .cards .inrCardHdr {
  min-height: 51px;
}
/* .fleetWpr .carousel-inner {height: 66vh;} */
.txViewWpr .fleetWpr .carousel-inner {height: 80vh;}
/* .txViewWpr .eleParaTbl .inrCardBody{height: 200px;} */
/* .txViewWpr .tblGrp .relayListTbl .inrCardBody{min-height:450px} */
.fleetWpr .table.eleParaTbl th{font-size: 0.813rem; padding: 0.5rem;height: 55px;}
.fleetWpr .table.eleParaTbl td{font-size: 0.75rem;}
.selectassetWpr {width: 68%;}
.chartGrp .pr-card .card-body{padding: 0 10px;}
.txViewWpr .tblGrp .relayListTbl .inrCardBody {height: 385px;}
.txViewWpr .chartGrp .pr-card .card-body {height: 200px;}
.txViewWpr .eleParaTbl .inrCardBody p {margin-top: 7%;}
.fullscreen .txViewWpr .eleParaTbl .inrCardBody p {margin-top: 11%;}
.txViewWpr .tblGrp .relayListTbl .inrCardBody p {margin-top: 50%;}
.fullscreen .txViewWpr .alertTbl .inrCardBody, .fullscreen .txViewWpr .eleParaTbl .inrCardBody {height: 220px;}
.fullscreen .txViewWpr .tblGrp .relayListTbl .inrCardBody {height: 510px;}
.fleetWpr .eleParaTbl.inrCardBody {height: 64vh;}
.txViewWpr .fleetWpr .chartGrp .inrCardHdr{min-height: 52px;}
.txViewWpr .alertTbl .inrCardBody {height: 165px;}
.txViewWpr .eleParaTbl .inrCardBody {height: 165px;}
/* .fleetWpr .table.eleParaTbl td a{padding: 1.2rem 1rem;} */
.hSQKHD{min-height: 36px!important;}

}
@media (max-width: 1366px) {
  body {
    font-size: 12px;
  }
  .paramWpr .paramBox .card-body,
  .paramGraph .card-body {
    padding: 0px;
    height: 75px;
  }
  /* .js-plotly-plot .plotly .user-select-none {
    margin-top: -34px;
  } */
  .js-plotly-plot {
    height: 325px !important  ;
  }
  .custom-plot-height {
    height: 143px !important  ;
    margin-top: 43px;
  }
  .txViewWpr .chartGrp .pr-card .card-body {
    height: 160px;
  }
  .unbalaWpr {
    height: 273px;
  }
  .unbalaWpr .no-data {
    margin-top: 87px;
  }
  .separator {
    height: 217px;
    border-right: 1px solid #eee;
  }
  .sidebarLeft {
    z-index: 1040;
    position: aboulte;
    padding-top: 0px;
    background: #e0e4e9;
    float: left;
    width: auto;
    height: 100vh;
    /* overflow-y: scroll; */
  }
  .sidebarLeft .fa,
  .sidebarRight .fa {
    padding: 4px 6px;
  }
  .kzZBXj {
    overflow: hidden;
    white-space: unset !important;
    text-overflow: ellipsis;
    font-size: 0.813rem;
  }
  .custom-cell {
    font-size: 12px;
    font-weight: 550;
  }
  .hSQKHD{
    font-size: 12px !important;;
      font-weight: 500 !important;  /* Add !important */
      color: rgba(0, 0, 0, 0.87);min-height: 36px !important;
  }
  .dropdown-item {
    font-size: 0.688rem;
    font-weight: 500;
  }
  .noDataTbl.table thead th {
    border: none;
    font-size: 0.813rem;
  }
  .info-box .info-box-text {
    font-size: 0.813rem !important;
    font-weight: 600 !important;
  }
  .brand-link {
    padding: 10px 0.5rem;
  }
  .pr-card .breadcrumbHdr.card-header .breadcrumb {
    padding: 7px 10px;
  }
  .breadcrumb a {
    font-size: 0.813rem;
    line-height: 0.875rem;
  }
  .breadcrumb-item,
  .breadcrumb-item + .breadcrumb-item::before {
    line-height: 25px;
  }
  .pr-card .breadcrumbHdr.card-header .breadcrumb {
    background: transparent;
    margin-bottom: 0;
    font-size: 0.813rem;
  }
  .inrCardHdr h5,
  .cardHdr h5 {
    font-size: 15px;
  }
  .login-section .input-group > .input-group-prepend > .input-group-text {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    font-size: 0.875rem;
  }
  .apexcharts-xaxis-label {
    font-family: "Montserrat", sans-serif !important;
    font-size: 0.688rem !important;
    font-weight: 500 !important;
  }
  .apexcharts-legend-text {
    font-family: "Montserrat", sans-serif !important;
    font-size: 0.688rem !important;
    font-weight: 500 !important;
  }
  .apexcharts-yaxis-label {
    font-family: "Montserrat", sans-serif !important;
    font-size: 0.688rem !important;
    font-weight: 500 !important;
  }
  .apexcharts-yaxis-title-text {
    font-family: "Montserrat", sans-serif !important;
    font-size: 0.688rem !important;
    font-weight: 500 !important;
  }
  .apexcharts-xaxis-title-text {
    font-family: "Montserrat", sans-serif !important;
    font-size: 0.688rem !important;
    font-weight: 500 !important;
  }

  .transformer {
    background-size: 18px;
    height: 20px;
  }
  .selco {
    background-size: 19px;
    height: 19px;
  }
  .ems {
    background-size: 18px;
    height: 18px;
  }
  .other,
  .hvac,
  .motor {
    background-size: 20px;
    height: 20px;
  }

  .ups {
    height: 20px;
  }
  .nav-link.active {
    font-size: 0.75rem;
    line-height: 14px;
  }
  .nav-link {
    font-size: 12px;
  }
  label:not(.form-check-label):not(.custom-file-label),
  .form-control {
    font-size: 12px;
    line-height: 12px;
  }

  #editDevice #modal_map iframe {
    height: 310px;
  }

  .mdlContent.card-body {
    padding: 10px;
  }
  .mdlContent.card-body {
    margin: 10px 0;
  }
  .btn {
    padding: 5px 10px;
  }
  .pr-card.tableCard .card-header {
    padding: 20px 20px 10px 20px;
  }
  .form-control.uploadWpr {
    line-height: 24px;
  }
  .ftrBtn .btn,
  .ftrBtn input[type="submit"].btn-block {
    width: 375px;
    font-weight: 600;
  }
  .chartWpr {
    min-height: 440px;
  }
  .sidebar-collapse .relayTbl .relayTblscroll {
    height: 329px;
  }
  .sidebar-collapse .relayOprCnt .relayTblscroll {
    height: 188px;
  }

  .relayOprCnt .relayTblscroll {
    height: 162px;
  }
  .enryLosPg .inrCardHdr,
  .sidebar-collapse .enryLosPg .inrCardHdr {
    min-height: 50px;
  }
  .sidebar-collapse .enryLosPg .inrCardBody {
    min-height: 70px;
  }
  .inrCardHdr h5,
  .cardHdr h5 {
    font-size: 0.813rem;
  }
  .h3,
  h3 {
    font-size: 0.875rem;
  }
  .table td {
    font-size: 12px;
    font-weight: 500;
  }
  .gcBbvz {
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    line-height: normal;
    padding-left: 16px;
    padding-right: 16px;
    word-break: break-word;
    font-weight: 500;
    font-size: 12px;
  }
  .valueFont {
    font-size: 12px !important;
    font-weight: 500;
  }
  .smlCardHdr h5 {
    color: #000;
    font-size: 0.813rem;
    font-weight: 600;
  }
  .inrCardBody h3 {
    line-break: anywhere;
    font-size: 20px;
  }
  .h5,
  h5 {
    font-size: 0.813rem;
    margin: 0;
    font-weight: 600;
    color: #000;
  }
  .ytitle {
    font-size: 0.688rem;
    font-weight: 500;
  }
  .smlCardHdr {
    min-height: 51px;
  }
  .powerWpr .enrgConsBox .smlCardHdr {
    flex: 1 1 100%; /* Ensures the card stretches */
    display: flex; /* This makes the card content flexible */
    flex-direction: column; /* Ensures label and number are stacked */
    justify-content: space-between; /* Makes the content spread evenly */
    min-height: 80px; /* Set a minimum height if needed */
  }
  .enrgConsBox .inrCardBody {
    flex: 1 1 100%; /* Ensures the card stretches */
    display: flex; /* This makes the card content flexible */
    flex-direction: column; /* Ensures label and number are stacked */
    justify-content: space-between; /* Makes the content spread evenly */
    min-height: 70px; /* Set a minimum height if needed */
  }
  .tblScroll {
    height: auto;
    overflow-y: scroll;
  }
  .relayTbl .relayTblscroll {
    height: 330px;
  }

  .col-md-4 .powerFctBox p {
    min-height: 36px;
  }
  .sidebar-collapse .loadBar .inrCardBody {
    min-height: 325px;
  }
  .loadBar .inrCardBody {
    min-height: 297px;
  }
  .sidebar-collapse .powerWpr .enrgConsBox .smlCardHdr {
    flex: 1 1 100%; /* Ensures the card stretches */
    display: flex; /* This makes the card content flexible */
    flex-direction: column; /* Ensures label and number are stacked */
    justify-content: space-between; /* Makes the content spread evenly */
    min-height: 80px; /* Set a minimum height if needed */
  }
  .react-datepicker {
    font-size: 0.688rem;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    line-height: 16px;
  }
  .tmpTbl.card {
    margin-bottom: 62px;
  }
  .tmpTbl .inrCardBody {
    margin: 0;
  }
  .tmpTbl .inrCardBody,
  .pFactor .inrCardBody {
    padding: 11px;
  }
  .pfTbl {
    margin: 5px 0;
  }
  .pfWidth {
    width: 35px;
  }
  .sidebar-collapse .pfWidth {
    width: 37px;
  }
  .sldSwitchWpr.switch {
    width: 70px;
  }
  .sldSwitchWpr .switch-light {
    width: 40px;
    height: 15px;
  }
  .sldSwitchWpr a {
    height: 13px;
    width: 13px;
    right: 61%;
  }
  .breadcrumbHdr .form-select {
    line-height: 1;
  }
  .breadcrumbHdr .dataTables_filter input[type="checkbox"] {
    height: 16px;
  }
  .table .relayTblscroll th {
    width: 75%;
  }
  .loadProfile .inrCardBody {
    min-height: 297px;
}
.sidebar-collapse .loadProfile .inrCardBody {
  min-height: 325px;
}
.no-data {
  text-align: center; /* Center the text */
  font-weight: 500;  /* Increase font weight */
}
.no-datacard {
  text-align: center; /* Center the text */
  font-weight: 500;  /* Increase font weight */
  margin-top: 133px;
}
.selectassetWpr {width: 67%;}
.fleetWpr .innerWpr{height: 70vh;}
/* .fleetWpr .carousel-inner {height: 70vh;} */
.txViewWpr .fleetWpr .carousel-inner{height: 75vh;}
.selectassetWpr {width: 63%;}
/* .txViewWpr .alertTbl .inrCardBody{height: 120px;} */
.txViewWpr .tblGrp .relaysTbl .inrCardBody{height: 320px;}
.eleTbl .inrCardBody{overflow-y: scroll; height: 130px;}
.txViewWpr .tblGrp .relayListTbl .inrCardBody{height: 230px;}
.txViewWpr .eleParaTbl .inrCardBody{height: 90px; overflow-y: scroll;}
.txViewWpr .alertTbl .inrCardBody {height: 100px; overflow-y: scroll;}
.txViewWpr .inrCardHdr h5, .txViewWpr .cardHdr h5{font-size: 0.75rem;}
.fleetWpr .chartGrp .inrCardHdr {min-height: 39px;}
.fleetWpr .eleParaTbl.inrCardBody {height: 62vh;}
.fleetWpr .table.eleParaTbl td a {padding: 0.5rem 0.5rem;}
.topBtnCard .switch, .fleetWpr .switch{width: 140px;}
.topBtnCard .switch-light > span span:last-child, .fleetWpr .switch-light > span span:last-child{left: 45%;}
.topBtnCard .switch-light > span span, .fleetWpr .switch-light > span span{font-size: 0.625rem;margin-left: 95px;}
.topBtnCard .switch-light, .fleetWpr .switch-light {height: 21.5px;}
.topBtnCard .switch-light > span, .fleetWpr .switch-light > span{line-height: 21.5px;}
.topBtn.inrCardBody .btn {font-size: 0.625rem;padding: 0.125rem 0.625rem;}
.fleetWpr .inrCardHdr, .fleetWpr .pr-card .breadcrumbHdr.card-header {padding: 5px;}
.fleetWpr .table thead th{font-size: 0.688rem;}
.fleetWpr .table td {font-size: 0.625rem;}
.latestArtTbl .inrCardBody {height: 175px;}
.selectassetWpr .form-control {font-size: 0.875rem;}
.txViewWpr .tblGrp .relayListTbl .inrCardBody p {margin-top: 30%;}
.chartGrp .pr-card .card-body {height: 160px;}
.txViewWpr .chartGrp .pr-card .card-body {height: 160px;}
.fullscreen .fleetWpr .innerWpr{height: 75vh;}
.fullscreen .fleetWpr .table td {font-size: 0.688rem;}
.fullscreen .inrCardHdr h5, .fullscreen .cardHdr h5 {font-size: 1rem;}
.fullscreen .h5, .fullscreen h5 {font-size: 1.063rem;}
.fullscreen .fleetWpr .eleParaTbl.inrCardBody {height: 69vh;}
.fullscreen .fleetWpr .table.eleParaTbl td a {padding: 1rem 1rem;}
.fullscreen .txViewWpr .inrCardHdr h5, .fullscreen .txViewWpr .cardHdr h5 {font-size: 0.75rem;}
.fullscreen .txViewWpr .fleetWpr .inrCardHdr, .fullscreen .txViewWpr .fleetWpr .pr-card .breadcrumbHdr.card-header {min-height: 25px;}
.fullscreen .txViewWpr .chartGrp .pr-card .card-body {height: 155px;}
.fullscreen .txViewWpr .alertTbl .inrCardBody, .fullscreen .txViewWpr .eleParaTbl .inrCardBody {height: 165px;}
.fullscreen .txViewWpr .fleetWpr .table td {font-size: 0.688rem;}
.fullscreen .txViewWpr .tblGrp .relayListTbl .inrCardBody {height: 370px;}
.fullscreen .txViewWpr .eleParaTbl .inrCardBody p {margin-top: 9%;}
.fullscreen .txViewWpr .fleetWpr .carousel-inner {height: 81.5vh;}
.fullscreen .table th, .fullscreen .table td {padding: 8px 5px;}
.fullscreen .latestArtTbl .inrCardBody {height: 220px;}
.txViewWpr .alertTbl .inrCardBody p {margin-top: 5%;}
.txViewWpr .alertTbl th:first-child{width: 23%;}
.txViewWpr .eleParaTbl .inrCardBody p {margin-top: 4%;}
.td_width{width: 35%;}
.switchSmall small {height: 22px; width: 50px;}
.switchSmall small:before {width: 16px; height: 16px;}
.switchSmall input:checked ~ small:before{left:0;}
}

/* media for 55inch TV */
@media (max-width: 1280px) {
  body {
    font-size: 12px;
  }

  .sidebarLeft {
    z-index: 1040;
    position: aboulte;
    padding-top: 10px;
    background: #e0e4e9;
    float: left;
    width: auto;
    height: 100vh;
    /* overflow-y: scroll; */
  }
  .voltageCard .inrCardBody,
  .currentCard .inrCardBody {
    height: 460px;
  }

  .kzZBXj {
    overflow: hidden;
    white-space: unset !important;
    text-overflow: ellipsis;
    font-size: 0.813rem;
  }
  .unbalaWpr {
    height: 245px;
  }
  .separator {
    height: 190px;
  }
  .unbalaWpr .no-data {
    margin-top: 77px;
  }
  .custom-cell {
    font-size: 12px;
    font-weight: 550;
  }
  .hSQKHD {
    font-size: 12px !important;
    font-weight: 500 !important; /* Add !important */
    color: rgba(0, 0, 0, 0.87);
  }
  .dropdown-item {
    font-size: 0.688rem;
    font-weight: 500;
  }
  .noDataTbl.table thead th {
    border: none;
    font-size: 0.813rem;
  }
  .info-box .info-box-text {
    font-size: 0.813rem !important;
    font-weight: 600 !important;
  }
  .brand-link {
    padding: 10px 0.5rem;
  }
  .pr-card .breadcrumbHdr.card-header .breadcrumb {
    padding: 7px 10px;
  }
  .breadcrumb a {
    font-size: 0.813rem;
    line-height: 0.875rem;
  }
  .breadcrumb-item,
  .breadcrumb-item + .breadcrumb-item::before {
    line-height: 25px;
  }
  .pr-card .breadcrumbHdr.card-header .breadcrumb {
    background: transparent;
    margin-bottom: 0;
    font-size: 0.813rem;
  }
  .inrCardHdr h5,
  .cardHdr h5 {
    font-size: 15px;
  }
  .login-section .input-group > .input-group-prepend > .input-group-text {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    font-size: 0.875rem;
  }
  .apexcharts-xaxis-label {
    font-family: "Montserrat", sans-serif !important;
    font-size: 0.563rem !important;
    font-weight: 500 !important;
  }
  .apexcharts-legend-text {
    font-family: "Montserrat", sans-serif !important;
    font-size: 0.563rem !important;
    font-weight: 500 !important;
  }
  .apexcharts-legend-marker {
    border-radius: 50% !important;
    height: 6px !important;
    width: 6px !important;
  }
  .apexcharts-yaxis-label {
    font-family: "Montserrat", sans-serif !important;
    font-size: 0.625rem !important;
    font-weight: 500 !important;
  }
  .apexcharts-yaxis-title-text {
    font-family: "Montserrat", sans-serif !important;
    font-size: 0.688rem !important;
    font-weight: 500 !important;
  }
  .apexcharts-xaxis-title-text {
    font-family: "Montserrat", sans-serif !important;
    font-size: 0.688rem !important;
    font-weight: 500 !important;
  }

  .transformer {
    background-size: 18px;
    height: 20px;
  }
  .aqiq {
    background-size: 28px;
    height: 20px;
  }
  .other,
  .hvac,
  .motor {
    background-size: 20px;
    height: 20px;
  }
  .ems {
    background-size: 13px;
    height: 13px;
  }
  .ups {
    height: 20px;
  }
  .nav-link.active {
    font-size: 12px;
    line-height: 12px;
  }
  .nav-link {
    font-size: 12px;
  }
  label:not(.form-check-label):not(.custom-file-label),
  .form-control {
    font-size: 12px;
    line-height: 12px;
  }

  #editDevice #modal_map iframe {
    height: 310px;
  }

  .mdlContent.card-body {
    padding: 10px;
  }
  .mdlContent.card-body {
    margin: 10px 0;
  }
  .btn {
    padding: 5px 10px;
  }
  .pr-card.tableCard .card-header {
    padding: 20px 20px 10px 20px;
  }
  .form-control.uploadWpr {
    line-height: 24px;
  }
  .ftrBtn .btn,
  .ftrBtn input[type="submit"].btn-block {
    width: 350px;
    font-weight: 600;
  }
  .chartWpr {
    min-height: 440px;
  }
  .sidebar-collapse .relayTbl .relayTblscroll {
    height: 329px;
  }
  .sidebar-collapse .relayOprCnt .relayTblscroll {
    height: 188px;
  }

  .relayOprCnt .relayTblscroll {
    height: 162px;
  }
  .enryLosPg .inrCardHdr,
  .sidebar-collapse .enryLosPg .inrCardHdr {
    min-height: 50px;
  }
  .sidebar-collapse .enryLosPg .inrCardBody {
    min-height: 70px;
  }
  .inrCardHdr h5,
  .cardHdr h5 {
    font-size: 12px;
  }
  .h3,
  h3 {
    font-size: 0.875rem;
  }
  .table td {
    font-size: 12px;
    font-weight: 500;
  }
  .gcBbvz {
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    line-height: normal;
    padding-left: 16px;
    padding-right: 16px;
    word-break: break-word;
    font-weight: 500;
    font-size: 12px;
  }
  .valueFont {
    font-size: 12px !important;
    font-weight: 500;
  }
  .smlCardHdr h5 {
    color: #000;
    font-size: 0.813rem;
    font-weight: 600;
  }
  .inrCardBody h3 {
    line-break: anywhere;
    font-size: 20px;
  }
  .h5,
  h5 {
    font-size: 0.813rem;
    margin: 0;
    font-weight: 600;
    color: #000;
  }
  .ytitle {
    font-size: 0.688rem;
    font-weight: 500;
  }
  .smlCardHdr {
    min-height: 51px;
  }
  .powerWpr .enrgConsBox .smlCardHdr {
    flex: 1 1 100%; /* Ensures the card stretches */
    display: flex; /* This makes the card content flexible */
    flex-direction: column; /* Ensures label and number are stacked */
    justify-content: space-between; /* Makes the content spread evenly */
    min-height: 80px; /* Set a minimum height if needed */
  }
  .enrgConsBox .inrCardBody {
    flex: 1 1 100%; /* Ensures the card stretches */
    display: flex; /* This makes the card content flexible */
    flex-direction: column; /* Ensures label and number are stacked */
    justify-content: space-between; /* Makes the content spread evenly */
    min-height: 70px; /* Set a minimum height if needed */
  }
  .tblScroll {
    height: auto;
    overflow-y: scroll;
  }
  .relayTbl .relayTblscroll {
    height: 330px;
  }

  .col-md-4 .powerFctBox p {
    min-height: 36px;
  }
  .sidebar-collapse .loadBar .inrCardBody {
    min-height: 325px;
  }
  .loadBar .inrCardBody {
    min-height: 297px;
  }
  .sidebar-collapse .powerWpr .enrgConsBox .smlCardHdr {
    flex: 1 1 100%; /* Ensures the card stretches */
    display: flex; /* This makes the card content flexible */
    flex-direction: column; /* Ensures label and number are stacked */
    justify-content: space-between; /* Makes the content spread evenly */
    min-height: 80px; /* Set a minimum height if needed */
  }
  .react-datepicker {
    font-size: 0.688rem;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    line-height: 16px;
  }
  .tmpTbl.card {
    margin-bottom: 65px;
  }
  .tmpTbl .inrCardBody {
    margin: 0;
  }
  .pfTbl {
    margin: 5px 0;
  }
  .pfWidth {
    width: 35px;
  }
  .sidebar-collapse .pfWidth {
    width: 37px;
  }
  .sldSwitchWpr.switch {
    width: 70px;
  }
  .sldSwitchWpr .switch-light {
    width: 40px;
    height: 15px;
  }
  .sldSwitchWpr a {
    height: 13px;
    width: 13px;
    right: 61%;
  }
  .breadcrumbHdr .form-select {
    line-height: 1;
  }
  .breadcrumbHdr .dataTables_filter input[type="checkbox"] {
    height: 16px;
  }
  .table .relayTblscroll th {
    width: 75%;
  }
  .loadProfile .inrCardBody {
    min-height: 297px;
}
.sidebar-collapse .loadProfile .inrCardBody {
  min-height: 325px;
}
.no-data {
  text-align: center; /* Center the text */
  font-weight: 500;  /* Increase font weight */
}
.no-datacard {
  text-align: center; /* Center the text */
  font-weight: 500;  /* Increase font weight */
  margin-top: 133px;
}
.sidebar-collapse .enryPg .cards .inrCardHdr {
  min-height: 51px;
}
.fleetWpr .innerWpr, .fullscreen .fleetWpr .innerWpr {height: 76vh;}
.table th, .table td {padding: 0.188rem;}
/* .latestArtTbl .inrCardBody {height: 200px;} */
.latestArtTbl .inrCardBody {height: 150px;}
.fleetWpr .eleParaTbl.inrCardBody {height: 60vh;}
.fleetWpr .table.eleParaTbl td a {padding: 1rem 0.5rem;}
.topBtn.inrCardBody {padding: 5px 10px;}
.fleetWpr .table.eleParaTbl th {font-size: 0.75rem; height: 55px;}
.fleetWpr .table.eleParaTbl td {font-size: 0.688rem;}
.txViewWpr .fleetWpr .table td, .txViewWpr .fleetWpr .eleParaTbl th, .txViewWpr .tblGrp .relayListTbl th {font-size: 0.625rem;}
.selectassetWpr .form-control {height: 23px;}
.selectassetWpr .form-control {font-size: 0.813rem;padding: 0.25rem 0.625rem;}
.fullscreen .txViewWpr .alertTbl .inrCardBody{height: 170px;}
.fullscreen .txViewWpr .eleParaTbl .inrCardBody {height: 150px;}
.fullscreen .txViewWpr .tblGrp .relayListTbl .inrCardBody {height: 360px;}
.txViewWpr .chartGrp .pr-card .card-body {height: 155px;}
.chartGrp .pr-card .card-body p {margin-top: 27%;}
.chartGrp .col-md-6.col-lg-6 .pr-card .card-body p {margin-top: 16%;}
.latestArtTbl .inrCardBody p {margin-top: 6%;}
.eleParaTbl tbody p{margin-top: 19%;}
.txViewWpr .tblGrp .relayListTbl .inrCardBody p {margin-top: 44%;}
/* .txViewWpr .alertTbl .inrCardBody, .txViewWpr .eleParaTbl .inrCardBody{height: 145px;}
.txViewWpr .fleetWpr .carousel-inner {height: 81vh;}
.txViewWpr .eleParaTbl .inrCardBody p{margin-top: 9%;}
.txViewWpr .tblGrp .relayListTbl .inrCardBody {height: 335px;}
.fullscreen .txViewWpr .chartGrp .pr-card .card-body {height: 175px;}
.fullscreen .chartGrp .pr-card .card-body p {margin-top: 25%;}
.fullscreen .chartGrp .col-md-6.col-lg-6 .pr-card .card-body p {margin-top: 14%;} */

}
@media (max-width: 1024px) {
.fullscreen .txViewWpr .fleetWpr .inrCardHdr{min-height: 40px;}
.fullscreen .txViewWpr .eleParaTbl .inrCardBody {height: 133px;}
.fleetWpr .table.eleParaTbl td {font-size: 0.625rem;}
.fleetWpr .eleParaTbl.inrCardBody {height: 69vh;}
.eleParaTbl tbody p {margin-top: 30%;}
.inrCardBody h3 {font-size: 16px;}
.busbarCard .inrCardHdr{min-height: 48px;}

}
@media (min-width: 912px) {
  .brand-text {
    margin-left: 10px !important;
    -webkit-animation-name: fadeIn !important;
    animation-name: fadeIn !important;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    visibility: visible !important;
  }
}

/* 
@media (max-width: 1024px) {
  .ftrBtn .btn,
  .ftrBtn input[type="submit"].btn-block {
    width: 268px;
    font-weight: 600;
  }
  .ph_gauge .inrCardBody {
    min-height: 180px;
  }
  .sidebar-collapse .ph_gauge .inrCardBody {
    min-height: 170px;
  }
  .ph_gauge.col-lg-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ph_bar.col-lg-8 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .relayTblscroll {
    height: 290px;
  }
  .sidebar-collapse .enryPg .cards .inrCardHdr {
    min-height: 50px;
  }
  .sidebar-collapse .enryPg .cards .inrCardBody {
    min-height: 65px;
  }
  .enryLosPg .inrCardHdr,
  .sidebar-collapse .enryLosPg .inrCardHdr {
    min-height: 62px;
  }
  .h3,
  h3 {
    font-size: 13px;
  }
  .powerFctBox h2 {
    font-size: 30px;
  }
  .tblScroll {
    height: 427px;
  }
  .sidebar-collapse .tblScroll {
    height: 408px;
  }
  .relayTbl .relayTblscroll {
    height: 345px;
  }
  .sidebar-collapse .relayTbl .relayTblscroll {
    height: 320px;
  }
  .energyWpr .smlCardHdr {
    min-height: 60px;
  }
  .powerWpr .enrgConsBox .smlCardHdr {
    min-height: 85px;
  }
  .powerWpr .enrgConsBox .inrCardBody {
    min-height: 85px;
  }
  .table .relayTblscroll th {
    width: 60%;
  }
}

@media (min-width: 992px) {
  .sidebar-mini.sidebar-collapse .main-sidebar,
  .sidebar-mini.sidebar-collapse .main-sidebar::before {
    margin-left: 0;
    width: 4rem;
  }
}

@media (max-width: 991.98px) {
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav)
    .content-wrapper {
    margin-left: 60px;
  }
}

@media (max-width: 912px) {
 .brand-text {
    margin-left: -10px;
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    visibility: hidden;
  } 
  .modal.right .modal-dialog {
    width: 330px;
  }

  .ftrBtn .btn,
  .ftrBtn input[type="submit"].btn-block {
    width: 291px;
    font-weight: 600;
  }

  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav)
    .main-header {
    margin-left: 65px;
  }

  .brand-link .brand-image {
    max-height: 35px;
  }

  .layout-fixed .brand-link {
    width: 63px;
  }

   .relayTblscroll {
    display: contents;
  }

  .loadBar.col-md-5 {
    flex: 100%;
    max-width: 100%;
  }
  .loadProfile.col-md-4 {
    flex: 75%;
    max-width: 75%;
  }
  .relayCnt .relayTblscroll {
    height: 320px;
    display: block;
  }

  .enryPg .col-md {
    flex-basis: content;
  }
  .sidebar-collapse .enryPg .cards .inrCardHdr {
    min-height: 40px;
  }
  .sidebar-collapse .enryPg .cards .inrCardBody {
    min-height: 55px;
  }
  .sidebar-collapse .ph_gauge .inrCardBody {
    min-height: 180px;
  }
  .sidebar-collapse .tblScroll {
    height: auto;
  }


}
@media (max-width: 820px) {
  .loadProfile.col-md-4 {
    flex: 65%;
    max-width: 65%;
  }
  .relayCnt.col-md-3 {
    flex: 35%;
    max-width: 35%;
  }
  .relayCnt .relayTblscroll {
    height: 220px;
  }
}
@media (max-width: 800px) {
  .ph_gauge.col-lg-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ph_bar.col-lg-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .gaugeWpr {
    margin-top: 30px;
  }
  .gaugeWpr .speedometer {
    height: 200px !important;
  }
  .pwrFtrPg .col-md-4 {
    max-width: 100%;
    flex: 100%;
    margin-bottom: 20px;
  }
}
@media (max-width: 768px) {
  .login-section .avatar-bg .avatar-wrap {
    height: 130px;
  }
  .login-section .login-form .login-form-inner {
    max-width: 340px;
    margin: 50px auto;
  }
  .login-section .login-row {
    height: auto;
    position: relative;
  }
  .login-section .avatar-bg .avatar-img {
    width: 80px;
    height: 80px;
  }
  .login-section .avatar-bg::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: url(../images/login-bg.png) no-repeat center center;
    background-size: cover;
    width: 100%;
    height: 180px;
    z-index: 9;
    right: 0;
  }
  .relayCnt .relayTblscroll {
    height: 200px;
  }
  .lastValue {
    font-size: 22px;
  }
  .avgBox.powerFctBox {
    width: 100%;
  }
}
@media (max-width: 663px) {
  .login-section .avatar-bg {
    height: 40%;
  }

  .tableCard .dataTables_length,
  .tableCard .dataTables_info {
    text-align: center;
    margin-bottom: 10px;
  }

  .dataTables_filter,
  .dataTables_paginate .pagination {
    justify-content: center;
  }

  .alertModal .col-md-10 {
    width: 80%;
  }

  .alertModal .col-md-2 {
    width: 20%;
  }

  .chartWpr {
    min-height: auto;
  }

  .enrgConsBox {
    margin: 5px 0;
    height: auto;
    display: block;
  }

  .card-body h6 {
    text-align: center;
  }

  .loadProfile.col-md-4 {
    flex: 100%;
    max-width: 100%;
  }
  .relayCnt.col-md-3 {
    flex: 100%;
    max-width: 100%;
  }
  .relayCnt .relayTblscroll {
    display: contents;
  }

  .enryPg .col-md {
    flex-basis: auto;
  }
  .enryLosPg .inrCardHdr,
  .enryLosPg .sidebar-collapse .inrCardHdr {
    min-height: auto;
  }
  .h3,
  h3 {
    font-size: 20px;
  }
  .sidebar-collapse .enryLosPg .inrCardBody {
    min-height: 55px;
  }
  .sidebar-collapse .ph_gauge .inrCardBody {
    min-height: auto;
  }
  .lastValue {
    width: 30%;
  }
  .sidebar-collapse .loadProfile .inrCardBody {
    min-height: 204px;
  }
  .separator {
    display: none;
  }
  .sidebar-collapse .powerWpr .enrgConsBox .smlCardHdr {
    min-height: 40px;
  }
  .powerWpr .enrgConsBox .inrCardBody {
    min-height: 55px;
  }
}

@media (max-width: 540px) {
  .login-section .avatar-bg {
    height: 30%;
  }

  .login-section .login-form-inner-head img {
    width: 100px;
  }
}
@media (max-width: 430px) {
}
@media (max-width: 414px) {
  .mdlContent.card-body {
    padding: 20px 10px;
  }
}

@media (max-width: 412px) {
  #modal_map iframe {
    height: 300px;
  }
} */

.gdkAMt {
  overflow: initial !important;
  overflow-wrap: break-word !important;
  white-space: break-spaces !important;
}
