@media print {
  .page-main {
    padding-top: 50px;
    padding-bottom: 50px;
    display: block;
  }
  .watermark {
    position: fixed;
    top: 50vh;
    z-index: 9;
    width: 50vw;
    page-break-after: always;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.2;
  }
}
.info-box{min-height: 40px!important;}
.info-box .info-box-icon{width: 30px!important;}
.bg-greenPin {
  background-color: #92D050 !important;
}
.bg-redPin {
  background-color: #FF0000 !important;
}
.bg-grayPin {
  background-color: #657790 !important;
}
.info-box .info-box-text{font-size: 16px; font-weight: 700;}

@-moz-document url-prefix() { 
  .info-box .info-box-text{font-weight: 500!important;}
}

@media (max-width: 1600px) {
.info-box .info-box-text {font-size: 12px;}
.info-box .info-box-icon {width: 20px !important; height: 28px;}
}