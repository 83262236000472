.tree {
    margin: 0 0 0 10%;
}

.tree ul {
    position: relative;
    padding: 1em 0;
    white-space: nowrap;
    margin: 0 auto;
    text-align: center;
}

.tree ul::after {
    content: '';
    display: table;
    clear: both;
}

.tree li {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    list-style-type: none;
    position: relative;
    padding: 1em 0.5em 0 0.5em;
}

.tree li::before,
.tree li::after {
    content: '';
    position: absolute;
    top: 0;
    right: 50%;
    border-top: 1px solid #333;
    width: 50%;
    height: 1em;
}

.tree li::after {
    right: auto;
    left: 50%;
    border-left: 1px solid #333;
}

.tree li:only-child::after,
.tree li:only-child::before {
    display: none;
}

.tree li:only-child {
    padding-top: 0;
}

.tree li:first-child::before,
.tree li:last-child::after {
    border: 0 none;
}

.tree li:last-child::before {
    border-right: 1px solid #333;
    border-radius: 0 5px 0 0;
}

.tree li:first-child::after {
    border-radius: 5px 0 0 0;
}

/* .tree ul ul::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    border-left: 1px solid #333;
    width: 0;
    height: 1em;
  } */
.tree .stext {
    font-weight: bold;
    display: inline-block;
    font-size: small;
    min-width: 120px;
    top: 20px;
    position: relative;
}

.mainTitle line::before {
    content: '';
    position: absolute;
    top: 0;
    left: 70% !important;
}

.tree li a {
    padding: 0.5em 0.75em;
    text-decoration: none;
    display: inline-block;
    border-radius: 5px;
    color: #fff;
    position: relative;
    top: 21px;
}

.mainTitle a {
    color: white !important;
    background-color: #657790;
    width: 300px;
}

.leftTitle {
    background-color: #0095DA;
    padding: 0.5em 1em !important;
    border-radius: 10px;
}

.tree li a.leftTitle,
.tree li a.rightTitle {
    top: 16px;
}

.leftTitle::before {
    content: '';
    position: absolute;
    top: -65%;
    left: 50%;
    border-left: 1px solid #333;
    width: 0;
    height: 1.8em;
}

/* up */
.arrow::after {
    content: '';
    position: absolute;
    top: -240%;
    left: 197%;
    border-width: 6px;
    border-style: solid;
    border-color: transparent transparent black transparent;
}

/* down */
.arrow2 {
    content: '';
    position: absolute;
    top: 21.5%;
    left: 61.4%;
    border-width: 6px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

/* left */
.arrow3 {
    content: '';
    position: absolute;
    top: 24.5%;
    left: 75.5%;
    border-width: 6px;
    border-style: solid;
    border-color: transparent black transparent transparent;
    transform: rotate(360deg);
}


.arrow4 {
    content: '';
    position: absolute;
    top: 21.6%;
    left: 139%;
    border-width: 6px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.arrow5 {
    content: '';
    position: absolute;
    top: 38.5%;
    left: 49%;
    border-width: 6px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.arrow6 {
    content: '';
    position: absolute;
    top: 55.1%;
    left: 49%;
    border-width: 6px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.arrow7 {
    content: '';
    position: absolute;
    top: 72%;
    left: 49%;
    border-width: 6px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

/* right */
.arrow8 {
    content: '';
    position: absolute;
    top: 25%;
    left: 22%;
    border-width: 6px;
    border-style: solid;
    border-color: transparent black transparent transparent;
    transform: rotate(180deg);
}

.arrow9 {
    content: '';
    position: absolute;
    top: 41.5%;
    left: 22%;
    border-width: 6px;
    border-style: solid;
    border-color: transparent black transparent transparent;
    transform: rotate(180deg);
}

.arrow10 {
    content: '';
    position: absolute;
    top: 14.7%;
    left: -65%;
    border-width: 6px;
    border-style: solid;
    border-color: transparent black transparent transparent;
    transform: rotate(180deg);
}

/* three phase */
.threearrow10 {
    content: '';
    position: absolute;
    top: 4.2%;
    left: -60%;
    border-width: 6px;
    border-style: solid;
    border-color: transparent black transparent transparent;
    transform: rotate(180deg);
}

.threearrow11 {
    content: '';
    position: absolute;
    top: 4.5%;
    left: -20.5%;
    border-width: 6px;
    border-style: solid;
    border-color: transparent black transparent transparent;
    transform: rotate(0deg);
}

.arrow11 {
    content: '';
    position: absolute;
    top: 14.7%;
    left: -25.4%;
    border-width: 6px;
    border-style: solid;
    border-color: transparent black transparent transparent;
    transform: rotate(0deg);
}

.arrow12 {
    content: '';
    position: absolute;
    top: 39.9%;
    left: -45.2%;
    border-width: 6px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.arrow13 {
    content: '';
    position: absolute;
    top: 61%;
    left: -45.2%;
    border-width: 6px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.rightTitle {
    background-color: #0095DA;
    padding: 0.5em 1em !important;
    border-radius: 10px;
}

.rightTitle::before {
    content: '';
    position: absolute;
    top: -65%;
    left: 50%;
    border-left: 1px solid #333;
    width: 0;
    height: 1.8em;
}

.tree .rightLine::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    border-bottom: 1px solid #333;
    width: 5em;
    height: 0;
}

.tree .downLine:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    width: 0;
    height: 2.5em;
}

.rightbox2 {
    background-color: #0095DA;
    /* padding: 0.5em 1em !important; */
    border-radius: 10px !important;
    left: 13%;
}

.r2space {
    margin-top: -20% !important;
}

.tree .rightbox2::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    border-bottom: 1px solid #333;
    width: 4.8em;
    height: 0;
}

.rightbox3 {
    background-color: #0095DA;
    padding: 0.5em 0.75em;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    position: absolute;
    left: 132%;
    top: -6%;
}

.rightbox3::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    height: 2em;
    width: 0;
    /* transform: translateX(-50%); */
}

.rightLine {
    width: 300px;
    color: white !important;
    background-color: #657790;
    padding: 0.5em 6em !important;
    margin-left: 21.7%;
}

.rightbox {
    color: white !important;
    background-color: #657790;
    padding: 0.5em 3em;
    text-decoration: none;
    display: inline-block;
    border-radius: 5px;
    color: #fff;
    position: absolute;
    left: 129%;
    top: 7.5%;
}


.leftLine {
    background-color: #0095DA;
    padding: 0.5em 1em;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    position: absolute;
    left: -15%;
    top: 8%;
}

.leftLine::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    border-bottom: 1px solid #333;
    width: 4.3em;
    height: 0;
}

.leftLine2 {
    background-color: #0095DA;
    padding: 0.5em 1em;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    position: absolute;
    left: -61%;
    top: 3%;
}

.leftLine2::before {
    content: '';
    position: absolute;
    top: 115%;
    left: 47%;
    border-bottom: 1px solid #333;
    width: 13em;
    height: 0;
}

.leftLine2::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 47%;
    border-left: 1px solid #333;
    height: 3.2em;
    width: 0;
    /* transform: translateX(-100%); */
}

.leftup1 {
    color: white !important;
    background-color: #657790;

    padding: 0.5em 3em;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    position: absolute;
    left: -70%;
    top: -18%;
}

.leftup1::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    border-bottom: 1px solid #333;
    width: 3.3em;
    height: 0;
}

.leftup1::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    height: .4em;
    width: 0;
    /* transform: translateX(-100%); */
}

.leftupR {
    width: 70px;
    height: 63px;
    background-color: #657790;
    padding: 0.5em 1em;
    text-decoration: none;
    display: inline-block;
    border-radius: 100%;
    color: #fff;
    position: absolute;
    left: -26%;
    top: -30%;
}

.leftupR::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    height: 5.2em;
    width: 0;
}

.leftupL {
    width: 70px;
    height: 63px;
    background-color: #657790;
    padding: 0.5em 1em;
    text-decoration: none;
    display: inline-block;
    border-radius: 100%;
    color: #fff;
    position: absolute;
    left: -93%;
    top: -30%;
}

.leftupL::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    height: 1.2em;
    width: 0;
}

.leftupL2 {
    background-color: #0095DA;

    padding: 0.5em 1em;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    position: absolute;
    left: -98%;
    top: -15%;
}

.tree .m1 {
    background-color: #0095DA;
    padding: 0.5em 1em;
    border-radius: 10px;
    left: 15%
}

.mspace {
    margin-left: 8%
}

.tree .m2 {
    width: 300px;
    color: white !important;
    background-color: #657790;
    padding: 0.5em 5em;
    border-radius: 5px;
    left: 0%
}

.tree .m4 {
    background-color: #0095DA;
    padding: 0.5em 1em;
    border-radius: 10px;
    left: 0%
}

.tree .m3 {
    width: 85px;
    height: 85px;
    color: white !important;
    background-color: #657790;
    padding: 0.5em 1em;
    border-radius: 100%;
    left: 267%;
    display: table-cell;
    vertical-align: middle;
}

.leftupL2::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 45%;
    border-left: 1px solid #333;
    height: 2em;
    width: 0;
}

.leftupL2::after {
    content: '';
    position: absolute;
    top: 178%;
    left: 45%;
    border-bottom: 1px solid #333;
    width: 6.5em;
    height: 0;
}

.leftupL3 {
    content: '';
    position: absolute;
    top: 12%;
    left: -20%;
    border-left: 1px solid #333;
    height: 7.1em;
    width: 0;
}

.leftupL4 {
    content: '';
    position: absolute;
    top: 33%;
    left: -20%;
    border-bottom: 1px solid #333;
    width: 16em;
    height: 0;
}

.leftLine3 {
    background-color: #0095DA;
    padding: 0.5em 1em;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    position: absolute;
    left: -61%;
    top: 18%;
}

.leftLine3::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 47%;
    border-left: 1px solid #333;
    height: 1.8em;
    width: 0;
    /* transform: translateX(-50%); */
}

.leftLine4 {
    width: 225px;
    color: white !important;
    background-color: #657790;

    padding: 0.5em 4em;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    position: absolute;
    left: -70%;
    top: 31%;
}

.leftLine4::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    height: 2.3em;
    width: 0;
    /* transform: translateX(-50%); */
}

.leftLine5 {
    background-color: #0095DA;
    padding: 0.5em 1em;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    position: absolute;
    left: -61%;
    top: 45%;
}

.leftLine5::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 47%;
    border-left: 1px solid #333;
    height: 1.5em;
    width: 0;
    /* transform: translateX(-50%); */
}

.leftLine6 {
    width: 85px;
    height: 85px;
    color: white !important;
    background-color: #657790;
    padding: 0.5em 1em;
    text-decoration: none;
    display: inline-block;
    border-radius: 50% !important;
    color: #fff;
    position: absolute;
    left: -57%;
    top: 57.5%;
}

/* three phase */

.space {
    margin-top: 5%;
}

.threep3 {
    background-color: blue;

    /* padding: 0.1em 2em; */
    min-width: 88px;
    min-height: 25px;
    text-decoration: none;
    display: inline-block;
    border-radius: 50px;
    color: #fff;
    position: absolute;
    left: -52.5%;
    top: 2%;
}

.threep3::before {
    content: '';
    position: absolute;
    top: 120%;
    left: 50%;
    border-bottom: 1px solid #333;
    width: 10.2em;
    height: 0;
}

.threep3::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    height: 4.2em;
    width: 0;
    /* transform: translateX(-100%); */
}

.threep2 {
    background-color: #e5ac03;

    /* padding: 0.1em 2em; */
    min-width: 88px;
    min-height: 25px;
    text-decoration: none;
    display: inline-block;
    border-radius: 50px;
    color: #fff;
    position: absolute;
    left: -52.5%;
    top: -4%;
}

.threep2::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    height: .4em;
    width: 0;
}

.threep1 {
    background-color: red;

    /* padding: 0.1em 2em; */
    min-width: 88px;
    min-height: 25px;
    text-decoration: none;
    display: inline-block;
    border-radius: 50px;
    color: #fff;
    position: absolute;
    left: -52.5%;
    top: -9.8%;
}

.threep1::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    height: .3em;
    width: 0;
}

.threePleftupR {
    color: white !important;
    background-color: #657790;
    padding: 0.5em 1em;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    position: absolute;
    left: -24%;
    top: -35%;
}

.threePleftupR::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    height: 4.5em;
    width: 0;
}

.threePleftupL {
    color: white !important;
    background-color: #657790;
    padding: 0.5em 1em;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    position: absolute;
    left: -89%;
    top: -35%;
}

.threePleftupL::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    height: 1.3em;
    width: 0;
}

.threePleftupL2 {
    background-color: #0095DA;
    padding: 0.5em 1em;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    position: absolute;
    left: -90.5%;
    top: -25%;
}

.threePleftupL2::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 45%;
    border-left: 1px solid #333;
    height: 1em;
    width: 0;
}

.threePleftupL2::after {
    content: '';
    position: absolute;
    top: 135%;
    left: 45%;
    border-bottom: 1px solid #333;
    width: 5.5em;
    height: 0;
}

.leftupN1 {
    width: 225px;
    color: white !important;
    background-color: #657790;
    padding: 0.5em 3em;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    position: absolute;
    left: -70%;
    top: -5%;
}

.leftupN1::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    border-bottom: 1px solid #333;
    width: 3.2em;
    height: 0;
}

.leftupN1::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-left: 1px solid #333;
    height: 0.3em;
    width: 0;
    /* transform: translateX(-100%); */
}

.righttext {
    font-weight: bold;
    min-width: 82px;
    position: absolute;
    font-size: 11px;
    top: 5em;
    left: 67em;
}

.righttext2 {
    min-width: 48px;
    font-weight: bold;
    position: absolute;
    font-size: 11px;
    top: 11em;
    left: 49em;
}

.righttext3 {
    min-width: 48px;
    font-weight: bold;
    position: absolute;
    font-size: 11px;
    top: 11em;
    left: -15em;
}

.righttext4 {
    min-width: 48px;
    font-weight: bold;
    position: absolute;
    font-size: 11px;
    top: 11em;
    left: -2em;
}

.leftLine2::after {
    height: 4.2em;
}

.righttext41 {
    position: absolute;
    font-size: 11px;
    top: 6em;
    left: -15em;
    min-width: 50px;
}

.righttext42 {
    position: absolute;
    font-size: 11px;
    top: 8.3em;
    left: -15em;
    min-width: 50px;
}

.righttext43 {
    position: absolute;
    font-size: 11px;
    top: 10.7em;
    left: -15em;
    min-width: 50px;
}

.dataWidth {
    min-width: 135px;
    min-height: 36.6px;
}

.tree.space .leftLine {
    left: -12%;
}

.tree.space .leftup1 {
    left: -65%;
}

.tree.space .leftLine::before {
    width: 3.2em;
}

.tree.space .arrow2 {
    left: 62.1%;
}

.tree.space .arrow::after {
    left: 185%;
}

.tree.space .arrow4 {
    top: 21.5%;
}

.tree.space .arrow8 {
    top: 25.1%;
}

.tree.space .arrow12,
.tree.space .arrow13 {
    left: -41.2%;
}

.tree.space .arrow9 {
    top: 41.7%;
}

.tree.space .righttext41 {
    top: 5em;
}

.tree.space .righttext42 {
    top: 7.5em;
}

.tree.space .righttext43 {
    top: 10.1em;
}

.tree.space .leftLine3,
.tree.space .leftLine5 {
    left: -57%;
}

.tree.space .leftLine4 {
    left: -66%;
}

.tree.space .leftLine6 {
    left: -53%;
}

.tree.space .stext.mspace {
    left: 45px;
}

.tree.space .threep3::before {
    top: 190%;
    width: 12.2em;
}

.tree.space .righttext3 {
    left: -1em;
}

/* .tree li a:hover,
  .tree li a:hover+ul li a {
    background: #e9453f;
    color: #fff;
    border: 1px solid #e9453f;
  }
  
  .tree li a:hover+ul li::after,
  .tree li a:hover+ul li::before,
  .tree li a:hover+ul::before,
  .tree li a:hover+ul ul::before {
    border-color: #e9453f;
  } */

/* Media query for smaller screens */

@media (max-width: 1600px) {
    .tree {
        margin-left:26%;
    }
    .tree li a{font-weight: 500;}
    .tree.space {
        margin-left:19%;
    }
    .tree.space .leftup1 {
        left: -62%;
    }
    .threePleftupR{left: -27%;top: -34%;}
    .threearrow11 {
        left: -24%;
        top: 3.8%;
    }
    .threePleftupL{left: -86%;}
    .rightbox2{left: 11%;}
    .threePleftupL2:before{left: 49%;}
    .threePleftupL2:after{left: 49%;width: 6.5em;}
    .tree.space .leftLine3, .tree.space .leftLine5 {
        left: -56%;
    }
    .tree.space .leftLine4 {
        left: -65%;
    }
    .leftLine5{top: 44%;}
    .tree.space .leftLine6 {
        left: -52%;
    }
    .tree.space .threep3:before {
        top: 160%;
        width: 14.6em;
    }
    .leftupL4{top: 33%; width: 20em;}
    .leftLine6{padding: 0.9em 1em;}
    .threearrow10{left: -58%;}
    .leftupL3{height: 7.7em;}
    .righttext3, .righttext4{top: 10em;}
    .rightbox3{left: 122.5%;}
    .righttext{top: 4em;left: 65em;}
    .threePleftupL2{left: -89.5%; top: -25.7%;}
    .tree.space .arrow4 {top: 22%;left: 130.5%;}
    .tree .rightbox2::before {width: 4em;}
    .arrow3 {top: 24.3%; left: 75.1%;}
    .arrow5 {top: 39%; left: 48.5%;}
    .arrow:after{top: -223%;}
    .leftTitle:before{top: -59%;}
    .rightbox{left:122%;top: 8%;}
    .threep2{top: -3.5%;}
    .threep3{top: 3%;}
    .leftup1::before {width: 3.3em;}
    .tree.space .threearrow11 {left: -24.1%;}
}

@media (max-width: 1366px) {
    

    .mainTitle a {
        padding: 0.5em 70px !important;
    }

    .righttext4 {top: 10.3em; left: -1em;}

    .leftTitle,
    .rightTitle,
    .leftLine3,
    .leftLine5,
    .rightbox3 {
        padding: 0.5em 1em !important;
    }

    .rightbox3 {
        left: 133%;
    }

    .leftLine4 {
        left: -39%;
    }

    .rightLine {
        margin-left: 22%;
    }

    .leftupN1 {
        padding: 0.5em 1.5em;
    }

    .rightbox {
        left: 132%;
    }

    .righttext {top: 4em;
        left: 69em;
    }

    .righttext2 {top: 10em;
        left: 55em;
    }

    .rightTitle::before {
        left: 50%;
    }

    .leftupN1 {
        left: -39%;
    }

    .leftupR {
        left: 3%; width: 60px; height: 55px;
    }
    .leftupR::before {top: 98%; height: 5.9em;}

    .leftupN1::before {width: 2.6em;
        left: 99%;
    }

    .leftupL2 {
        left: -63%;
    }
    .leftupN1::after {height: 0.5em;}

    .leftLine2,
    .leftLine3,
    .leftLine5 {
        left: -30%;
    }

    .leftLine6 {
        left: -26%;
    }

    .arrow::after {top: -222%;
        left: 177%;
    }

    .arrow2 {
        left: 79.5%;
    }

    .arrow3 {
        left: 94.1%;
    }

    .arrow4 {
        top: 19.5%;
    }

    .arrow4 {
        top: 21.6%;
        left: 140.7%;
    }

    .arrow5 {
        left: 67.4%;
    }

    .arrow6 {
        left: 67.4%;
    }

    .arrow7 {
        left: 67.9%;
    }

    .arrow8 {left: 40.4%;}

    .arrow9 {
        left: 41.1%;
        top: 41.5%;
    }

    .arrow10 {
        top: 14.6%;
        left: -36.3%;
    }

    .arrow11 {
        top: 14.3%;
        left: 3.6%;
    }

    .arrow12,
    .arrow13 {
        left: -16.2%;
    }

    .leftupL {left: -57%; width: 60px; height: 55px;}

    .leftupL2::after {top: 166%; width: 5.5em;}

    .leftTitle::before {top: -58%;
        left: 50%;
    }

    .tree li a {
        left: 19%;
    }

    .tree li a.rightbox2 {
        left: 25%;
    }

    .tree .stext {
        left: 19%;
        position: relative;
    }

    .tree .stext.mspace {
        left: 22%;
    }

    .tree .m4.downLine:after {
        left: 50%;
    }

    .rightbox2,
    .leftLine2 {
        padding: 0.5em 1em !important;
    }

    .tree .rightbox2::before {
        width: 2em;
    }

    .leftLine {
        left: 9%;
    }

    .leftTitle {
        left: 60px;
    }

    .leftLine2::before {
        width: 12.3em;
    }

    .leftLine::before,
    .tree.space .leftLine::before {
        width: 2.5em;
    }

    .leftupL3 {top: 13%;
        left: 8%;
        height: 7.2em;
    }

    .righttext3 {top: 10em;
        left: 9em;
    }

    .leftupL4 {
        left: 8%;
    }

    .tree .m1 {
        left: 35%;
    }

    .tree .m2 {
        padding: 0.5em 1em;
        left: 20%;
    }

    .tree .m4 {
        padding: 0.5em 1em;
        left: 20%;
    }

    .tree .m3 {
        left: 395%;
    }

    .threePleftupR,
    .threePleftupL {
        top: -27%;
    }

    .threePleftupL {
        left: -61%;
    }

    .threePleftupR {
        left: -6%;
    }

    .leftup1 {
        top: -9%;
        left: -49%;
    }

    .threePleftupL2 {
        top: -17%;
        left: -64%;
    }

    .threearrow10 {
        top: 14%;
        left: -44.5%;
    }

    .threePleftupL2::after {
        top: 137%;
        width: 5.1em;
    }

    .threep1 {
        left: -30.5%;
        top: -0.5%;
    }

    .threep2 {
        left: -30.5%;
        top: 6%;
    }

    .threep3 {
        left: -30.5%;
        top: 12.5%;
    }

    .tree.space .leftLine2,
    .tree.space .leftLine3,
    .tree.space .leftLine5 {
        left: -34.5%;
    }

    .tree.space .leftLine {
        left: 9%;
        top: 15%;
    }

    .tree.space .leftup1 {
        left: -40%;
    }

    .tree.space .arrow::after {
        left: 178%;
        top: -305%;
    }

    .tree.space .leftLine3 {
        top: 30%;
    }

    .tree.space .leftLine4 {
        left: -44%;
        top: 43%;
    }

    .tree.space .leftLine5 {
        top: 56%;
    }

    .tree.space .leftLine6 {
        left: -30%;
        top: 69%;
    }

    .tree.space li a.rightLine {
        top: 55px;
    }

    .tree.space li a.leftTitle,
    .tree.space li a.rightTitle {
        top: 35px;
    }

    .tree.space li a.leftTitle::before,
    .tree.space li a.rightTitle::before {
        top: -102%;
        height: 3.1em;
    }

    .tree.space .downLine:after {
        height: 3em;
    }

    .tree.space li a.m1,
    .tree.space li a.m2,
    .tree.space li a.m4,
    .tree.space li a.m3 {
        top: 52px;
    }

    .tree.space .rightbox {
        left: 138%;
        top: 69px;
    }

    .tree.space li a.rightbox2 {
        left: 31%;
        top: 55px;
    }

    .tree.space li .rightbox3 {
        top: 4px;
        left: 138.2%;
    }

    .tree.space .stext {
        top: 36px;
    }

    .tree.space .threep3::before {
        top: 120%;
        width: 13.8em;
    }

    .tree.space .leftupL3 {
        height: 6.9em;
        top: 20%;
    }

    .tree.space .leftupL4 {
        top: 39%;
    }

    .tree.space .stext.mspace {
        left: 145px;
        top: 51px;
    }

    .tree.space .arrow2 {
        left: 79%;
        top: 27.5%;
    }

    .tree.space .arrow3 {
        top: 30.2%;left: 93.3%;
    }

    .tree.space .arrow4 {
        left: 145.5%; top: 28%;
    }

    .tree.space .arrow5 {
        top: 44.6%; left: 67.8%;
    }

    .tree.space .arrow6 {
        top: 61.3%; left: 68.1%;
    }

    .tree.space .arrow7 {
        top: 78%; left: 68.1%;
    }

    .tree.space .arrow8 {
        left: 40.3%;
        top: 30.4%;
    }

    .tree.space .arrow9 {
        left: 41.5%;
        top: 46.4%;
    }

    .tree.space .threearrow10 {
        left: -37.2%; top: 11%;
    }

    .tree.space .threearrow11 {
        top: 11%; left: -3%;
    }

    .tree.space .arrow12 {
        top: 49.4%;
    }

    .tree.space .arrow13 {
        top: 70%;
    }

    .tree.space .leftup1::before {
        width: 2.8em;
    }

    .tree.space .threePleftupR::before {
        height: 5.4em;
    }

    .threePleftupL2::before {
        height: 1.1em;
    }

    .tree.space .arrow12,
    .tree.space .arrow13 {
        left: -20.6%;
    }

    .tree.space .righttext {
        top: 6.5em;
        left: 71em;
    }

    .tree.space .righttext2 {
        top: 12.5em;
        left: 58em;
    }

    .tree.space .righttext3 {
        top: 12.5em;
        left: 9.5em;
    }

    .tree.space .righttext41 {
        top: 8.4em;
        left: -5em;
    }

    .tree.space .righttext42 {
        top: 11em;
        left: -5em;
    }

    .tree.space .righttext43 {
        top: 13.7em;
        left: -5em;
    }
    .leftup1::after {height: 0.6em;}
}

@media (max-width: 1280px) {
    .tree.space {
        margin-left: 29%;
    }

    .threePleftupR,
    .threePleftupL {
        top: -26%;
    }

    /* .tree.space {
        padding: 10px 0 0 90px;
    } */

    .tree.space li a.leftTitle::before,
    .tree.space li a.rightTitle::before {
        top: -69%;
        height: 2.1em;
    }

    .tree.space .arrow::after {
        top: -259%;left: 177%;
    }

    .leftupL,
    .leftupR {
        top: -26%;
    }

    .leftupL {
        left: -54%;
    }

    .leftupN1 {
        top: -2%;
    }

    .leftLine2 {
        top: 6%;
    }

    .leftupL2 {
        top: -11%;
        left: -59%;
    }

    .leftupL2::after {
        width: 3.5em;
    }

    .leftLine {
        top: 11%;
    }

    .tree li a {
        top: 38px;
    }

    .tree li a.leftTitle,
    .tree li a.rightTitle,
    .tree .stext {
        top: 30px;
    }

    .righttext2,
    .righttext3,
    .righttext4 {
        top: 11em;
    }

    .tree .stext.mspace {
        top: 41px;
    }

    .leftupL3 {height: 7.7em;
        top: 16%;
    }

    .leftupL4 {
        top: 37%;
    }

    .rightbox {
        top: 11.5%;
    }

    .rightbox3 {
        top: -2%;
    }

    .righttext {
        top: 5.5em;
    }

    /* .arrow3 {
        top: 24.5%;
    } */
    .tree.space .threePleftupR::before {
        height: 5em;
    }
    
    .tree.space .arrow2 {
        left: 80.5%;}

    .arrow3 {
        top: 27.5%;
    }

    .arrow4 {
        top: 24.6%;
    }

    .arrow5 {top: 42%;
    }

    .arrow6 {
        top: 58%;
    }

    .arrow7 {
        top: 74.5%;
    }

    .arrow8 {
        top: 27.5%;
    }

    .arrow9 {
        top: 44.5%;
    }

    .arrow10 {
        top: 17.9%;
        left: -36%;
    }

    .arrow11 {
        top: 16.5%;
    }
    .leftupR::before {height: 5.6em;}
    .arrow::after {top: -211%;}
    .leftTitle::before {top: -53%;}
    .rightTitle::before {top: -53%;}
    .arrow2 {top: 24.5%;}
}

@media (max-width: 1024px) {
    .tree {
        padding: 10px 0 0 290px;
    }
    .tree.space {
        margin-left: 7%;
    }
    /* .tree.space {
        padding: 10px 0 0 330px;
    } */

}

@media (max-width: 412px) {
    .tree {
        padding: 10px 0 0 250px;
    }
}